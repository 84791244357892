import { Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useGetOuterSpacesQuery } from '../../api/outerSpaces';
import { useSelector } from 'react-redux';
import csvDownload from 'json-to-csv-export';

const ReportGenerator = () => {
  const userInfo = useSelector((state) => state.session.userInfo);
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const { data: outerSpaceData } = useGetOuterSpacesQuery(
    {},
    {
      skip: !userInfo.lanId,
    }
  );
  useEffect(() => {
    if (outerSpaceData) {
      const newReg = [];
      outerSpaceData.forEach((event) => {
        let temp = { ...event };
        event.bookedSessions.forEach((session) => {
          if (session['eventName'] === 'Music & Memories') {
            temp['Music'] = session['subEvent'];
          }
          if (session['eventName'] === 'Create & Connect') {
            temp['Create_Connect'] = session['subEvent'];
          }
          if (session['eventName'] === 'Food & Fun Times') {
            temp['Food_Fun_Times'] = session['subEvent'];
          }
        });
        if (temp['Music'] === undefined) {
          temp['Music'] = '';
        }
        if (temp['Create_Connect'] === undefined) {
          temp['Create_Connect'] = '';
        }
        if (temp['Food_Fun_Times'] === undefined) {
          temp['Food_Fun_Times'] = '';
        }
        delete temp['bookedSessions'];
        delete temp['__v'];
        newReg.push(temp);
      });

      setRegisteredUsers(newReg);
    }
  }, [outerSpaceData]);
  function downloadReport() {
    const dataToConvert = {
      data: registeredUsers,
      filename: 'TIIAM2024_Registered_Users',
      delimiter: ',',
      headers: ['Email', 'Created Date', 'LAN ID', 'Location', 'Mobile', 'Name', 'Pyramid', 'Music & Memories', 'Create & Connect', 'Food & Fun Times'],
    };
    csvDownload(dataToConvert);
  }

  const registerBtn = {
    textTransform: 'none',
    backgroundColor: '#333333',
    padding: '5px 20px',
    borderRadius: '5px',
    fontSize: '15px',
    '&:hover': {
      backgroundColor: '#333333',
      color: '#FFF',
      boxShadow: 'none',
    },
    '&:disabled': {
      color: '#5b5b5b',
    },
  };
  return (
    <>
      <Grid container justifyContent='center'>
        <Grid item sx={{ minHeight: '60vh', marginTop: '200px' }}>
          <Button variant='contained' startIcon={<FileDownloadIcon />} sx={registerBtn} onClick={downloadReport}>
            Download Report
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ReportGenerator;
