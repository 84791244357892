import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import styles from './Agenda.module.css';

const AgendaDay1 = () => {
  return (
    <Grid container sx={{ backgroundColor: '#f9eaea' }}>
      <Grid item md={6} textAlign={'left'}>
        <Card elevation={0} sx={{ background: 'transparent', padding: '40px', paddingLeft: '8%' }}>
          <CardContent>
            <Typography gutterBottom variant='h5' component='div' sx={{ color: '#808080' }}>
              Talks with TII
              <hr className={[styles['agenda-day1-title-hr']]} />
            </Typography>
            <Typography variant='h3' color='#333333' sx={{ fontWeight: '700' }}>
              Brett Craig & <br /> Gretchen McCarthy
            </Typography>
          </CardContent>
          <div style={{ display: 'flex' }}>
            <CardMedia
              component='img'
              alt='green iguana'
              image='https://gfc.target.com/tiiam/tiiam24/brett.png'
              sx={{ width: '30%', objectFit: 'contain', marginRight: '10%' }}
            />
            <CardMedia
              component='img'
              alt='green iguana'
              image='https://gfc.target.com/tiiam/tiiam24/gretchen.png'
              sx={{ width: '30%', objectFit: 'contain' }}
            />
          </div>
          <CardContent>
            <Typography variant='body1' color='#333333'>
              Join Brett Craig, Chief Information Officer and Gretchen McCarthy, Chief Supply Chain Logistics Officer, for a conversation where they dive deeper
              into the evolution of supply chain and tech capabilities in the past year and Target’s roadmap for growth. They will also outline priorities for
              2024 and share ways in which technology has enabled our advanced supply chain capabilities.
              <br />
              <br />
              <b>When</b>: 11.45 a.m. to 12.30 p.m., Tuesday, April 16 <br /> <b>Where</b>: In person at NXT 9th floor cafeteria, watch party at C2 café
              extension & online on Zoom
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6} className={styles['agenda-day1-img']}>
        <img src='https://gfc.target.com/tiiam/tiiam24/graphic_talkswithTII_r.png' alt='' />
      </Grid>
    </Grid>
  );
};

export default AgendaDay1;
