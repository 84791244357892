/* eslint-disable */
import { Button, Grid, Box, CardMedia, Typography } from "@mui/material";
// import {
//   useRegisterOuterSpacesMutation,
//   useGetOuterSpacesQuery,
//   useGetSeatingCapacityQuery,
// } from "../../api/outerSpaces";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import _ from "lodash";
import { Heading } from "enterprise-stencil";

const Register = (props) => {
  const { heading } = props;
  const userInfo = useSelector((state) => state.session.userInfo);
  const [outerspaceGetData, setouterspaceGetData] = useState([]);
  const [seatingCapacity, setSeatingCapacity] = useState([]);
  const [selectedSession, setSelectedSession] = useState("");
  // const { data: outerSpaceData } = useGetOuterSpacesQuery(
  //   {},
  //   {
  //     skip: !userInfo.lanId,
  //   }
  // );

  // const {
  //   data: seatingCapacityData,
  //   refetch,
  //   isFetching: availabilityCheckRunning,
  // } = useGetSeatingCapacityQuery({}, { skip: !userInfo.lanId });

  const [disableRegisterSania, setDisableRegisterSania] = useState();
  const [disableRegisterRicky, setDisableRegisterRicky] = useState();
  const [sessionsTakenSeats, setSessionsTakenSeats] = useState();

  // const [saveOuterSpaces] = useRegisterOuterSpacesMutation();
  function sessionwiseTakenSeats(registerRes) {
    let ricky = 0;
    let sania = 0;

    registerRes.forEach((registration) => {
      for (let i = 0; i < registration.bookedSessions.length; i++) {
        switch (registration.bookedSessions[i]) {
          case "ricky":
            ricky++;
            break;
          case "sania":
            sania++;
            break;
          default:
            break;
        }
      }
    });
    const sessionwiseTakenSeats = {
      ricky,
      sania,
    };
    setSessionsTakenSeats(sessionwiseTakenSeats);
    const hasAlreadyBooked = _.find(registerRes, {
      email: userInfo.email.toLowerCase(),
    });

    if (
      hasAlreadyBooked?.bookedSessions?.indexOf("sania") >= 0 ||
      sessionwiseTakenSeats?.sania >=
        _.find(seatingCapacityData, { session_id: "sania" }).seating_capacity
    ) {
      setDisableRegisterSania(true);
    }

    if (
      hasAlreadyBooked?.bookedSessions?.indexOf("ricky") >= 0 ||
      sessionwiseTakenSeats?.ricky >=
        _.find(seatingCapacityData, { session_id: "ricky" }).seating_capacity
    ) {
      setDisableRegisterRicky(true);
    }
  }
  // useEffect(() => {
  //   if (outerSpaceData && seatingCapacityData) {
  //     setouterspaceGetData(outerSpaceData);
  //     sessionwiseTakenSeats(outerSpaceData);
  //   }
  // }, [outerSpaceData, seatingCapacityData]);

  // useEffect(() => {
  //   if (seatingCapacityData) {
  //     setSeatingCapacity(seatingCapacityData);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [seatingCapacityData]);

  // useEffect(() => {
  //   if (!availabilityCheckRunning && selectedSession) {
  //     const selected = selectedSession;
  //     setSelectedSession("");
  //     console.log("data refreshed, Validate and proceed submit");
  //     const saniaSeatsFull =
  //       sessionsTakenSeats &&
  //       sessionsTakenSeats.sania >=
  //         _.find(seatingCapacityData, { session_id: "sania" }).seating_capacity;
  //     const rickySeatsFull =
  //       sessionsTakenSeats &&
  //       sessionsTakenSeats.ricky >=
  //         _.find(seatingCapacityData, { session_id: "ricky" }).seating_capacity;
  //     if (
  //       (selected === "sania" && !saniaSeatsFull) ||
  //       (selected === "ricky" && !rickySeatsFull)
  //     ) {
  //       handleRegister(selected);
  //     }
  //   }
  // }, [availabilityCheckRunning]);

  function availabilityCheck(sessionName) {
    setSelectedSession(sessionName);
    refetch();
  }
  function handleRegister(selected) {
    const existingUserData = _.find(outerspaceGetData, {
      email: userInfo.email.toLowerCase(),
    });

    if (existingUserData !== undefined) {
      const tempUserData = JSON.parse(JSON.stringify(existingUserData));
      tempUserData.bookedSessions.push(selected);
      tempUserData.bookedSessions = _.uniq(tempUserData.bookedSessions);
      saveOuterSpaces(tempUserData);
    } else {
      let registerData = {
        name: userInfo.fullName,
        email: userInfo.email.toLowerCase(),
        bookedSessions: [selected],
        lanId: userInfo.lanId,
      };

      saveOuterSpaces(registerData);
    }
  }

  const areSaniaSeatsAreFull =
    sessionsTakenSeats &&
    sessionsTakenSeats.sania >=
      _.find(seatingCapacityData, { session_id: "sania" }).seating_capacity;
  const areRickySeatsAreFull =
    sessionsTakenSeats &&
    sessionsTakenSeats.ricky >=
      _.find(seatingCapacityData, { session_id: "ricky" }).seating_capacity;

  const hasAlreadyBooked =
    outerspaceGetData.length > 0 &&
    userInfo.fullName !== "" &&
    _.find(outerspaceGetData, {
      email: userInfo.email.toLowerCase(),
    });
  return (
    <Box
      sx={{
        background:
          "linear-gradient(rgb(255, 255, 255) 0%, rgb(238, 231, 221) 100%)",
      }}
    >
      <Heading {...heading} />
      <Grid
        container
        spacing={2}
        sx={{ width: { md: "87%", xs: "100%" }, margin: "0 auto" }}
      >
        <Grid
          item
          sm={6}
          md={6}
          xs={12}
          sx={{
            // backgroundImage: `url(https://gfc.target.com/tiiam/tiiam23/ricky.png)`,
            position: "relative",
          }}
        >
          <CardMedia
            image={`https://gfc.target.com/tiiam/tiiam23/ricky.png`}
            sx={{
              width: "100%",
              height: { xl: 767, lg: 616, md: 421, sm: 360, xs: 401 },
            }}
          />
          {userInfo &&
            userInfo.lanId &&
            userInfo.lanId.split("")[0].toLowerCase() === "z" && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "37%",
                  left: {
                    xl: "70px",
                    lg: "61px",
                    md: "45px",
                    sm: "42px",
                    xs: "45px",
                  },
                }}
              >
                {/* <Button
                  variant="outlined"
                  sx={{
                    fontSize: { xl: 16, lg: 14, md: 12, sm: 8, xs: 8 },
                    borderRadius: 0,
                    borderColor: "#CC0000",
                    color: "#CC0000",
                    backgroundColor: "#fff",
                    textTransform: "none",
                    "&:hover": {
                      border: "1px solid #CC0000",
                      backgroundColor: "#fff",
                    },
                    "&.Mui-disabled": {
                      backgroundColor: "#f98c99",
                      color: "#CC0000",
                    },
                  }}
                  onClick={() => availabilityCheck("ricky")}
                  disabled={disableRegisterRicky}
                >
                  {hasAlreadyBooked?.bookedSessions?.indexOf("ricky") >= 0
                    ? "Registered"
                    : areRickySeatsAreFull
                    ? "Registrations closed"
                    : "Register"}
                </Button> */}
              </Box>
            )}
        </Grid>
        <Grid
          item
          sm={6}
          md={6}
          xs={12}
          sx={{
            // backgroundImage: `url(https://gfc.target.com/tiiam/tiiam23/sania.png)`,
            position: "relative",
          }}
        >
          <CardMedia
            image={`https://gfc.target.com/tiiam/tiiam23/sania.png`}
            sx={{
              width: "100%",
              height: { xl: 767, lg: 616, md: 421, sm: 360, xs: 401 },
            }}
          />
          {userInfo &&
            userInfo.lanId &&
            userInfo.lanId.split("")[0].toLowerCase() === "z" && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "40%",
                  left: {
                    xl: "70px",
                    lg: "61px",
                    md: "45px",
                    sm: "42px",
                    xs: "45px",
                  },
                }}
              >
                {/* {seatingCapacity &&
                _.find(seatingCapacity, { session_id: "sania" })
                  ?.is_reg_open ? (
                  <Button
                    variant="outlined"
                    sx={{
                      fontSize: { xl: 16, lg: 14, md: 12, sm: 8, xs: 8 },
                      borderRadius: 0,
                      borderColor: "#CC0000",
                      color: "#fff",
                      backgroundColor: "#CC0000",

                      textTransform: "none",
                      "&:hover": {
                        border: "1px solid #CC0000",
                        backgroundColor: "#CC0000",
                      },
                      "&.Mui-disabled": {
                        backgroundColor: "#c1b2a4",
                        color: "#fff",
                      },
                    }}
                    onClick={() => availabilityCheck("sania")}
                    disabled={disableRegisterSania}
                  >
                    {hasAlreadyBooked?.bookedSessions?.indexOf("sania") >= 0
                      ? "Registered"
                      : areSaniaSeatsAreFull
                      ? "Registrations closed"
                      : "Register"}
                  </Button>
                ) : (
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: {
                        xl: 18,
                        lg: 18,
                        md: 14,
                        sm: 12,
                        xs: 10,
                      },
                    }}
                  >
                    Registrations open soon!
                  </Typography>
                )} */}
              </Box>
            )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Register;
