import { Button, CircularProgress, Dialog, DialogContent, FormControl, FormLabel, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useGetPhotoscapesQuery, useUploadPhotosMutation } from '../../api/photoscapes';

const UploadPhoto = ({ handleClose, open }) => {
  const userStyles = {
    dialogBody: {
      padding: '40px',
      //   backgroundColor: '#198754',
    },
    closeBtnContainer: {
      position: 'absolute',
      right: '8px',
      top: '10px',
    },
    heading: {
      textAlign: 'center',
      fontFamily: 'HelveticaForTargetBold',
      fontSize: '30px',
      //   color: '#FFF',
      fontWeight: 'bold',
    },
    body: {
      fontSize: '30px',
      textAlign: 'center',
      fontFamily: 'HelveticaforTarget-Light',
      color: '#FFF',
    },
    closeBtn: {
      backgroundColor: 'transparent',
      border: 'none',
      '&:focus-visible': {
        outline: '2px dotted #666666',
        outlineOffset: 2,
      },
    },
  };

  const userInfo = useSelector((state) => state.session.userInfo);
  const [photosDesc, setPhotosDesc] = useState({});
  const [isReqSuccess, setIsReqSuccess] = useState(false);
  const [errorResponse, setErrorResponse] = useState(false);
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [formErrors, setFormErrors] = useState({
    attachmentError: '',
    pic1Error: '',
    pic2Error: '',
  });

  const [uploadPhotos, { data: responseData, isLoading: saveInProgress, isError: responseError }] = useUploadPhotosMutation();
  const { data: getResponseData } = useGetPhotoscapesQuery(
    { userInfo },
    {
      skip: !userInfo.lanId,
    }
  );

  useEffect(() => {
    if (responseData) {
      setIsReqSuccess(true);
    }
    if (responseError) {
      setErrorResponse(true);
    }
  }, [responseData, responseError, errorResponse]);

  useEffect(() => {
    if (getResponseData && getResponseData.length > 0) {
      setIsExistingUser(true);
    }
  }, [getResponseData, userInfo.email]);
  // hide the register button for A ids and already registered users
  useEffect(() => {
    setTimeout(() => {
      const allRegBtns = document.querySelectorAll('.register_button button');
      if (allRegBtns && allRegBtns.length > 0 && userInfo.lanId.split('')[0].toLowerCase() !== 'z') {
        allRegBtns.forEach((el) => {
          // el.style.display = 'none';
        });
      }
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isValidForm = () => {
    let errorVal = { ...formErrors };
    if (attachment.length === 0) {
      errorVal.attachmentError = 'you should upload atleast one photo';
    } else {
      errorVal.attachmentError = '';
    }

    setFormErrors(errorVal);
    const isError = Object.values(errorVal).some((k) => k !== '');
    return !isError;
  };

  function handleSubmit(e) {
    e.preventDefault();

    if (isValidForm()) {
      const payload = {
        lanId: userInfo.lanId,
        name: userInfo.fullName,
        email: userInfo.email,
        desc1: photosDesc['desc1'],
        desc2: photosDesc['desc2'],
        attachment: attachment,
      };
      uploadPhotos(payload);
      setAttachment([]);
      setPhotosDesc({ desc1: '', desc2: '' });
      setFormErrors({
        attachmentError: '',
        pic1Error: '',
        pic2Error: '',
      });
    } else {
      console.log('Erros ', formErrors);
    }
  }

  function handleClosePopUp() {
    // refetch();
    setAttachment([]);
    setPhotosDesc({ desc1: '', desc2: '' });
    setIsReqSuccess(false);
    setFormErrors({
      attachmentError: '',
      pic1Error: '',
      pic2Error: '',
    });
    handleClose();
  }
  const [attachment, setAttachment] = useState([]);

  const handleselectedFile = (event, picNumber) => {
    if (event.target.files[0] && event.target.files[0].size > 2000000 && event.target.files[0].size < 5000000) {
      setAttachment([...attachment, event.target.files[0]]);
      const errors = { ...formErrors };
      if (picNumber === 'pic1') {
        errors['pic1Error'] = '';
      }
      if (picNumber === 'pic2') {
        errors['pic2Error'] = '';
      }
      setFormErrors({ ...formErrors, ...errors });
    } else {
      const errors = { ...formErrors };
      if (picNumber === 'pic1') {
        errors['pic1Error'] = 'Image size should be between 2 MB to 5 MB';
      }
      if (picNumber === 'pic2') {
        errors['pic2Error'] = 'Image size should be between 2 MB to 5 MB';
      }
      setFormErrors({ ...formErrors, ...errors });
    }
  };

  return (
    <Dialog
      onClose={handleClosePopUp}
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '20px',
        },
      }}
    >
      <div style={userStyles.closeBtnContainer}>
        <button onClick={handleClosePopUp} aria-label='close' style={userStyles.closeBtn}>
          <CloseIcon sx={{ fontSize: '25px', mt: '5px', cursor: 'pointer', color: '#666' }} onClick={handleClosePopUp} />
        </button>
      </div>
      <DialogContent sx={{ padding: { sm: '40px', xs: '0 20px 20px 20px' } }}>
        {!isReqSuccess && !isExistingUser ? (
          <form onSubmit={handleSubmit}>
            <FormControl>
              <Typography variant='h6' component='h6' className='h4-fonts' sx={{ color: '#000', marginTop: { xs: '20px', sm: 0 } }}>
                {`Welcome ${userInfo.fullName}`}
              </Typography>

              <Typography
                variant='h6'
                component='h6'
                className='h4-fonts'
                sx={{ color: '#333333', fontWeight: 'bold', marginBottom: '10px', fontSize: { xs: '15px' } }}
              >
                You can submit up to 2 images of 2-5MB each (submit both together)
              </Typography>
              {formErrors.attachmentError !== '' && (
                <Typography variant='h6' component='h6' className='h4-fonts' sx={{ color: '#CC0000', marginBottom: '10px', fontSize: { xs: '15px' } }}>
                  {formErrors.attachmentError}
                </Typography>
              )}
              <FormLabel
                id='mobile'
                sx={{
                  color: '#333333 !important',
                  fontWeight: 'bold',
                  mt: '10px',
                  fontSize: { xs: '15px' },
                }}
              >
                Picture 1
              </FormLabel>

              <Button
                variant='text'
                component='label'
                sx={{
                  width: '100%',
                  justifyContent: 'left',
                  borderBottom: '2px solid',
                  borderRadius: 0,
                  color: '#000',
                }}
                endIcon={<AttachFileIcon />}
              >
                <span
                  style={{
                    fontSize: 20,
                    color: '#666',
                    textTransform: 'none',
                    width: '92%',
                    fontWeight: 400,
                  }}
                >
                  {attachment.length > 0 ? attachment[0].name : 'upload here'}
                </span>
                <input onChange={(e) => handleselectedFile(e, 'pic1')} type='file' hidden accept='image/*' />
              </Button>
              {formErrors.pic1Error && <span style={{ color: '#cc0000' }}>{formErrors.pic1Error}</span>}

              <TextField
                value={photosDesc['desc1']}
                required={attachment[0] && attachment[0].name ? true : false}
                onChange={(e) => setPhotosDesc({ ...photosDesc, desc1: e.target.value })}
                id='outlined-basic'
                label='Add your caption (140 characters)'
                variant='outlined'
                sx={{ mt: '20px' }}
                multiline={true}
                minRows={3}
                inputProps={{ maxLength: 140 }}
              />
              <FormLabel
                id='mobile'
                sx={{
                  color: '#333333 !important',
                  fontWeight: 'bold',
                  mt: '10px',
                  fontSize: { xs: '15px' },
                }}
              >
                Picture 2
              </FormLabel>

              <Button
                variant='text'
                component='label'
                sx={{
                  width: '100%',
                  justifyContent: 'left',
                  borderBottom: '2px solid',
                  borderRadius: 0,
                  color: '#000',
                }}
                endIcon={<AttachFileIcon />}
              >
                <span
                  style={{
                    fontSize: 20,
                    color: '#666',
                    textTransform: 'none',
                    width: '92%',
                    fontWeight: 400,
                  }}
                >
                  {attachment.length > 1 ? attachment[1].name : 'upload here'}
                </span>
                <input onChange={(e) => handleselectedFile(e, 'pic2')} type='file' hidden accept='image/*' />
              </Button>
              {formErrors.pic2Error && <span style={{ color: '#cc0000' }}>{formErrors.pic2Error}</span>}
              <TextField
                value={photosDesc['desc2']}
                required={attachment[1] && attachment[1].name ? true : false}
                onChange={(e) => setPhotosDesc({ ...photosDesc, desc2: e.target.value })}
                id='outlined-basic'
                label='Add your caption (140 characters)'
                variant='outlined'
                sx={{ mt: '20px' }}
                multiline={true}
                minRows={3}
                inputProps={{ maxLength: 140 }}
              />
              <Button
                variant='contained'
                type='submit'
                disabled={attachment.length <= 0}
                sx={{
                  mt: 2,
                  backgroundColor: '#CC0000',
                  borderRadius: '20px',
                  textTransform: 'lowercase',
                  width: '20%',
                  '&:hover': {
                    backgroundColor: '#CC0000',
                  },
                }}
              >
                {saveInProgress ? <CircularProgress size={20} sx={{ color: '#CC0000' }} /> : 'submit'}
              </Button>
              {errorResponse && (
                <Typography variant='h4' component='h3' className='h4-fonts' sx={{ color: '#CC0000', fontSize: '20px !important', mt: '10px' }}>
                  Something went wrong, please try again later.
                </Typography>
              )}
            </FormControl>
          </form>
        ) : (
          <>
            {isExistingUser && !isReqSuccess && (
              <>
                <Typography variant='h6' component='h5' className='h4-fonts'>
                  Uh-oh. You've already made a submission. Pictures can be submitted only once.
                </Typography>
              </>
            )}
            <>
              {isReqSuccess && (
                <>
                  {' '}
                  <Typography
                    variant='h3'
                    component='h5'
                    className='h4-fonts'
                    sx={{ marginBottom: '10px', fontWeight: 'bold', paddingTop: { xs: '20px' }, fontSize: { xs: '37px' } }}
                  >
                    <span style={{ color: '#CC0000' }}>Awesome!</span>
                  </Typography>
                  <Typography variant='h6' component='h5' className='h4-fonts'>
                    Your entry has been submitted. <br />
                    Your images should reflect on the Hub in 5 days.
                  </Typography>
                </>
              )}
            </>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UploadPhoto;
