/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from 'react-router-dom';
import { Grid, IconButton, Modal, Typography, Box, Button, CardMedia } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
// import UploadFileIcon from "@mui/icons-material/UploadFile";
import SearchIcon from '@mui/icons-material/Search';
import galleryImages from './GalleryImages.json';
import _ from 'lodash';
import { closeIcon } from '../../../assets/images/SiteImages';
// import photoscapeImages from "../2022/Photoscapes.json";
import { useGetAllPhotoscapesQuery } from '../../../api/photoscapes';
import { useSelector } from 'react-redux';
// import artfairImages from "../2022/ArtFair.json";
// import { useAuth } from "@praxis/component-auth";

const Images = (props) => {
  const tabValue = props.tabValue;
  const [images, setImages] = useState([]);
  const [isModalOpen, setModel] = useState(false);
  const [url, setURL] = useState('');
  const [altText, setAltText] = useState('');
  const [name, setName] = useState('');
  const [index, setIndex] = useState();
  const [galleryAPiData, setGalleryAPiData] = useState([]);
  const location = useLocation();

  // const auth = useAuth();
  // const { session } = auth;
  // const { lanId } = session ? session?.userInfo : "";
  const userInfo = useSelector((state) => state.session.userInfo);
  const { data: photoscapesData } = useGetAllPhotoscapesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      skip: !userInfo.lanId,
    }
  );

  useEffect(() => {
    if (photoscapesData) {
      const photosdata =
        location.pathname.slice(1) === 'gallery' && tabValue === 0
          ? photoscapesData[photoscapesData.length - 1]?.artfair
          : location.pathname.slice(1) === 'gallery' && tabValue === 1
          ? photoscapesData[photoscapesData.length - 1]?.photos_data
          : galleryImages;

      setGalleryAPiData(photosdata);
    }
  }, [photoscapesData]);

  // const galleryData =
  //   location.pathname.slice(1) === "gallery" ? galleryImages : photoscapesData;

  useEffect(() => {
    setImages(_.shuffle(galleryAPiData));
  }, [galleryAPiData]);

  const handleClick = (image, i) => {
    setIndex(i);
    setURL(image.src);
    setAltText(image.alt);
    setName(image.name);

    setModel(true);
  };

  const prevAsset = () => {
    if (index === 0) {
      const image = images[images.length - 1];
      setURL(image.src);
      setAltText(image.alt);
      setName(image.name);
      setIndex(images.length - 1);
      return;
    }
    const i = index - 1;
    const image = images[i];
    setURL(image.src);
    setAltText(image.alt);
    setIndex(i);
    setName(image.name);
  };

  const nextAsset = () => {
    if (index === images.length - 1) {
      const image = images[0];
      setURL(image.src);
      setAltText(image.alt);
      setName(image.name);
      setIndex(0);
      return;
    }
    const i = index + 1;
    const image = images[i];
    setURL(image.src);
    setAltText(image.alt);
    setName(image.name);
    setIndex(i);
  };

  useEffect(() => {
    const onArrowKeyPress = (event) => {
      const leftArrow = 37;
      const rightArrow = 39;

      if (event.keyCode === leftArrow) {
        prevAsset();
      } else if (event.keyCode === rightArrow) {
        nextAsset();
      }
    };
    document.addEventListener('keydown', onArrowKeyPress);
    return () => document.removeEventListener('keydown', onArrowKeyPress);
  }, [nextAsset, prevAsset]);

  const mobile = useMediaQuery('(max-width:755px)');
  const tab = useMediaQuery('(max-width:1008px)');
  const desktop = useMediaQuery('(max-width:1494px)');

  // const buttonLink =
  //   "https://forms.microsoft.com/pages/responsepage.aspx?id=88CH9iaRvUW5lLlt-KGckfH6nTkiQUpFh8EHDC0CDBBUQlFDR1NTNVpBWUdQRDVGNTVMWlVQRUs5NC4u";
  return (
    <>
      {location.pathname.slice(1) === 'gallery' ? (
        ''
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignContent: 'flex-start',
            flexDirection: 'column',
          }}
        >
          {/* {tabValue === 2 ? ( */}
          <Typography
            varinat='body1'
            sx={{
              textAlign: 'left',
              mb: '40px',
              px: '30px',
              fontSize: '20px',
            }}
          >
            Photoscapes at The Art Fair is your opportunity to share the best pictures you’ve taken. It could be a stunning landscape, something that inspires
            you, shows off your happy place, is a throwback to a fond memory, or anything else…just no selfies! Select entries will also feature at the TIIAM
            2023 finale!
          </Typography>
          {/* ) : (
            <Typography
              varinat="body1"
              sx={{
                textAlign: "left",
                mb: "40px",
                px: "30px",
                fontSize: "20px",
              }}
            >
              ArtFair
            </Typography>
          )} */}

          {/* <Button
            variant="outlined"
            target="_blank"
            component="a"
            href={buttonLink}
            className="button-textxl button-size photoscapes-copy"
            startIcon={
              <UploadFileIcon sx={{ width: "2.5rem", height: "2.5rem" }} />
            }
            disabled={
              lanId && lanId.split("")[0].toLowerCase() === "a" ? true : false
            }
            sx={{
              mb: "40px",
              mx: "30px",
              borderRadius: 0,
              backgroundColor: "transparent",
              borderColor: "#000",
              color: "#000",
              fontSize: 20,
              textTransform: "none",
              "&:hover": {
                border: "1px solid #000",
              },

              // "&:disabled": {
              //   border: "1px solid #fff",
              //   color: "#fff",
              // },
              width: { lg: "13%", md: "20%", sm: "40%", xs: "60%" },
              padding: "8px 15px",
            }}
          >
            Upload
          </Button> */}
        </Box>
      )}
      <Box
        container={'true'}
        //   className={classes.container}
        sx={{
          columnCount: mobile ? 2 : tab ? 3 : desktop ? 4 : 5,
          paddingBottom: 2,
          width: location.pathname.slice(1) === 'gallery' ? '80%' : '97%',
          margin: '0 auto',
          columnGap: 0,
        }}
        role='dialog'
        aria-label='images of 2020 elevate slideshow'
      >
        {!isModalOpen &&
          images.map((image, i) => {
            return (
              <>
                <Button
                  aria-label={image.alt}
                  key={image.src}
                  sx={{
                    position: 'relative',
                    margin: '0px 1px 2px',
                    display: 'flex',
                    background: '#ffffff',
                    '&:hover': {
                      opacity: 0.8,
                      '& $expandIcon': {
                        display: 'block !important',
                        zIndex: '100',
                      },
                      '.search-icon': {
                        display: 'block',
                      },
                    },
                    border: 'none',
                    padding: 0,
                    '&:focus-visible': {
                      opacity: 0.8,
                      outline: '2px dotted #666666',
                      outlineOffset: 2,
                    },
                  }}
                  onClick={() => handleClick(image, i)}
                >
                  <img src={image.src} alt={image.alt} style={{ width: '100%', cursor: 'pointer' }} />
                  <SearchIcon
                    fontSize='large'
                    className='search-icon'
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      margin: 'auto',
                      display: 'none',
                      color: '#fff',
                    }}
                  />
                </Button>
              </>
            );
          })}

        <Modal open={isModalOpen} onClose={() => setModel(false)} role='dialog' aria-label='images of 2020 elevate slideshow'>
          <div>
            {/* <Grid item className={classes.imageBoxHeader}> */}
            <Grid
              item
              sx={{
                height: '40px',
                backgroundColor: '#000000',
                fontFamily: 'HelveticaforTargetRegular',
                fontSize: 14,
                fontWeight: 'normal',
                color: '#e5e5e5',
                //   padding: 10,
                '&:focus': {
                  outline: 'none',
                },
              }}
            >
              <div
                style={{
                  display: 'flex',
                  paddingLeft: '15px',
                }}
              >
                {/* <div>{`${index + 1}/${images.length}`}</div> */}
                {location.pathname.slice(1) === 'gallery' ? (
                  ''
                ) : (
                  <h2
                    style={{
                      fontSize: '16px',
                      margin: 0,
                      fontFamily: 'HelveticaForTarget',
                    }}
                  >
                    {name}&nbsp;-
                  </h2>
                )}{' '}
                &nbsp;{' '}
                <h2
                  style={{
                    fontSize: '16px',
                    margin: 0,
                    fontFamily: 'HelveticaForTarget',
                  }}
                >
                  {altText}
                </h2>
              </div>
              {/* <Close onClick={() => setModel(false)} fontSize='large' style={{ cursor: 'pointer', position: 'absolute', top: 8, right: 2 }} /> */}
              <button
                //   className={classes.closeIcon}
                onClick={() => setModel(false)}
                aria-label='close'
                style={{
                  background: `url(${closeIcon}) no-repeat`,
                  float: 'right',
                  right: '2%',
                  top: '30px',
                  position: 'absolute',
                  fontSize: '40px !important',
                  cursor: 'pointer',
                  width: '20px',
                  height: '20px',
                  border: 'none',
                  //   [theme.breakpoints.down("md")]: {
                  //     width: "13px",
                  //   },
                }}
              ></button>
            </Grid>
            {/* <Grid container className={classes.lightBoxWrapper}> */}
            <Grid container>
              <Grid
                style={{
                  // backgroundImage: `url(${url})`,
                  backgroundColor: '#000000',
                  height: '100vh',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  // backgroundColor: "#484848",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                item
                xs={12}
                aria-label={altText}
              >
                <IconButton
                  aria-label='previous image'
                  style={{
                    fontSize: 50,
                    color: '#FFFFFF',
                    background: 'rgba(0, 0, 0, 0.5)',
                    backgroundImage: 'none',
                    height: '60px',
                    width: '60px',
                    margin: '10px',
                    //   fontSize: "36px",
                  }}
                  onClick={() => prevAsset()}
                >
                  <ChevronLeft fontSize='inherit' />
                </IconButton>
                <CardMedia
                  component='img'
                  image={url}
                  alt={altText}
                  sx={{
                    objectFit: 'contain',
                    height: '100vh',
                    width: '90%',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
                <IconButton
                  aria-label='next image'
                  // className={classes.arrowButton}
                  style={{
                    fontSize: 50,
                    color: '#FFFFFF',
                    background: 'rgba(0, 0, 0, 0.5)',
                    backgroundImage: 'none',
                    height: '60px',
                    width: '60px',
                    margin: '10px',
                  }}
                  onClick={() => nextAsset()}
                >
                  <ChevronRight fontSize='inherit' />
                </IconButton>
              </Grid>
            </Grid>
          </div>
        </Modal>
      </Box>
    </>
  );
};

// export default withStyles(styles)(Images);
export default Images;
