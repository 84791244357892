import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scrollIntoView } from "seamless-scroll-polyfill";

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    scrollIntoView(document.getElementsByTagName("BODY")[0], {
      behavior: "smooth",
      top: 0,
      left: 0,
    });
  }, [location]);

  return null;
}

export default ScrollToTop;
