import React from 'react';
import { Typography, Divider, Grid } from '@mui/material';

const Footer = () => {
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: '#E6E6E6',
          paddingLeft: { md: '6% !important', xs: '13% !important' },
          padding: { md: '15px', sm: '30', xs: '30px' },
        }}
      >
        <Grid
          item
          md={5}
          xs={12}
          className='body1-fontl'
          sx={{
            display: 'flex',
            textAlign: 'left',
            // flexDirection: { md: 'row', sm: 'column', xs: 'column' },
          }}
        >
          <Typography
            variant='body1'
            sx={{
              alignSelf: 'center',
              fontSize: { xs: '10px !important', md: '20px !important' },
              '& a': {
                fontSize: { xs: '10px !important', md: '20px !important' },
              },
            }}
          >
            Check out all the action on{' '}
            <a
              href='https://www.instagram.com/teamtargetinindia/?hl=en'
              target='_blank'
              rel='noreferrer'
              id='insta_btn'
              style={{
                color: '#cc0000',
                textDecoration: 'none',
                fontWeight: 'bold',
              }}
            >
              @teamtargetinindia
            </a>{' '}
            stories <br /> and tag us using #TIIAM2024 in your moments!
          </Typography>
        </Grid>
        <Grid item md={1} sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'start' }}>
          <Divider
            orientation='vertical'
            flexItem
            sx={{
              display: { md: 'block', sm: 'none', xs: 'none' },
              height: 32,
              alignSelf: 'center',
            }}
          />
        </Grid>
        <Grid
          item
          md={5}
          xs={12}
          className='body1-fontl'
          sx={{
            display: 'flex',
            textAlign: 'left',
            // flexDirection: { md: 'row', sm: 'column', xs: 'column' },
          }}
        >
          <Typography
            variant='body1'
            sx={{
              alignSelf: 'center',
              fontSize: { xs: '10px !important', md: '20px !important' },
              '& a': {
                fontSize: { xs: '10px !important', md: '20px !important' },
              },
            }}
          >
            Didn't find what you were looking for?
            <br /> Write to us at{' '}
            <a
              style={{
                color: '#cc0000',
                textDecoration: 'none',
                fontWeight: 'bold',
                wordBreak: 'break-all',
              }}
              href='mailto:TargetIndia.Communications@target.com'
            >
              TargetIndia.Communications@target.com
            </a>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
