import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, useMediaQuery } from "@mui/material";

function FaqsItem(props) {
  const [bgColour, setBgColour] = React.useState("#ffffff");
  const [isExpanded, updateExpandStatus] = React.useState(false);
  const [isMouseHover, updateMouseHoverStatus] = React.useState(false);
  const { title, desc } = props;

  const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Accordion
      sx={{ background: `${bgColour}`, textAlign: "left" }}
      onChange={(event, expanded) => {
        updateExpandStatus(expanded);
        if (expanded) {
          setBgColour("#F7F7F7");
        } else {
          if (!isMouseHover) {
            setBgColour("#ffffff");
          }
        }
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          "&:focus-visible": {
            opacity: 0.8,
            outline: "2px dotted #666666",
            outlineOffset: 2,
          },
          background: `${bgColour}`,
        }}
        onMouseEnter={() => {
          setBgColour("#F7F7F7");
          updateMouseHoverStatus(true);
        }}
        onMouseLeave={() => {
          updateMouseHoverStatus(false);
          if (!isExpanded) {
            setBgColour("#ffffff");
          }
        }}
      >
        <Typography variant="h6" style={{ lineHeight: mobile ? "1.4" : "1.5" }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          background: `${bgColour}`,
        }}
      >
        <Box
          sx={{
            "& p a": {
              fontSize: 15,
              fontWeight: "bold",
            },
          }}
        >
          <Box
            style={{
              fontSize: "15px",
              paddingLeft: "10px",
              paddingRight: "10px",
              fontFamily: "HelveticaForTarget",
            }}
            dangerouslySetInnerHTML={{ __html: desc }}
          ></Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default FaqsItem;
