import { Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import Images from "./Images";
import Videos from "./Videos";
import Videos2023 from "./Videos2023";

const GalleryStructure = (props) => {
  const { tabValue } = props;
  const mobile = useMediaQuery((theme) => theme.breakpoints.between(0, 767));

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  const [imagesTab, setImages] = useState(tabValue === 0 ? 1 : 0);

  return (
    <>
      <Grid container sx={{ width: "80%", margin: "30px auto 50px auto" }}>
        <Grid item md={12} sx={{ display: "flex", alignItems: "center" }}>
          <button
            style={
              imagesTab === 0
                ? {
                    fontSize: mobile ? "20px" : "22px",
                    color: "#cc0000",
                    // fontFamily: 'Tar',
                    cursor: "pointer",
                    border: "none",
                    background: "#ffffff",
                  }
                : {
                    fontSize: mobile ? "20px" : "22px",
                    color: "#666666",
                    // fontFamily: 'HelveticaforTargetRegular',
                    cursor: "pointer",
                    border: "none",
                    background: "#ffffff",
                  }
            }
            // style={{ marginRight: 20 }}
            onClick={() => setImages(0)}
          >
            Images
          </button>
          <div
            style={{
              height: "20px",
              borderLeft: "1.5px solid",
              margin: mobile ? "10px 2px" : "10px",
            }}
          ></div>
          <button
            style={
              imagesTab === 1
                ? {
                    fontSize: mobile ? "20px" : "22px",
                    color: "#cc0000",
                    // fontFamily: 'Tar',
                    cursor: "pointer",
                    border: "none",
                    background: "#ffffff",
                  }
                : {
                    fontSize: mobile ? "20px" : "22px",
                    color: "#666666",
                    // fontFamily: 'HelveticaforTargetRegular',
                    cursor: "pointer",
                    border: "none",
                    background: "#ffffff",
                  }
            }
            onClick={() => setImages(1)}
          >
            Videos
          </button>
        </Grid>
      </Grid>
      {imagesTab === 0 ? (
        <Images tabValue={tabValue} />
      ) : tabValue === 0 ? (
        <Videos2023 />
      ) : (
        <Videos />
      )}
    </>
  );
};

export default GalleryStructure;
