import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useGetOuterSpacesQuery, useRegisterOuterSpacesMutation } from '../../api/outerSpaces';
import { useSelector } from 'react-redux';

const RegisterForEvent = ({ handleClose, open }) => {
  const userStyles = {
    dialogBody: {
      padding: '40px',
      //   backgroundColor: '#198754',
    },
    closeBtnContainer: {
      position: 'absolute',
      right: '8px',
      top: '10px',
    },
    heading: {
      textAlign: 'center',
      fontFamily: 'HelveticaForTargetBold',
      fontSize: '30px',
      //   color: '#FFF',
      fontWeight: 'bold',
    },
    body: {
      fontSize: '30px',
      textAlign: 'center',
      fontFamily: 'HelveticaforTarget-Light',
      color: '#FFF',
    },
    closeBtn: {
      backgroundColor: 'transparent',
      border: 'none',
      '&:focus-visible': {
        outline: '2px dotted #666666',
        outlineOffset: 2,
      },
    },
  };
  const events = [
    {
      eventName: 'Music & Memories',
      subEvents: ['Music', 'Folkdance', 'Storytelling'],
    },
    {
      eventName: 'Create & Connect',
      subEvents: ['Painting', 'Sustainable Art', 'Origami', 'Clay Modelling'],
    },
    {
      eventName: 'Food & Fun Times',
      subEvents: ['Baked goods', 'Summer Coolers', 'Snacks'],
    },
  ];
  const userInfo = useSelector((state) => state.session.userInfo);
  const [registeredInfo, setRegisteredInfo] = useState([]);
  const [outerspaceGetData, setouterspaceGetData] = useState([]);
  const [mobileNumber, setMobileNumber] = useState('');
  const [mobileError, setMobileError] = useState(false);
  const [isReqSuccess, setIsReqSuccess] = useState(false);
  const [errorResponse, setErrorResponse] = useState(false);
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [saveEventRegistration, { data: responseData, isLoading: saveInProgress, isError: responseError }] = useRegisterOuterSpacesMutation();
  const { data: outerSpaceData, refetch } = useGetOuterSpacesQuery(
    {},
    {
      skip: !userInfo.lanId,
    }
  );

  useEffect(() => {
    if (responseData) {
      setIsReqSuccess(true);
    }
    if (responseError) {
      setErrorResponse(true);
    }
  }, [responseData, responseError, errorResponse]);

  useEffect(() => {
    if (outerSpaceData) {
      const existingUserData = _.find(outerspaceGetData, {
        email: userInfo.email,
      });
      if (existingUserData && existingUserData.email) {
        setIsExistingUser(true);
      }

      setouterspaceGetData(outerSpaceData);
    }
  }, [outerSpaceData, outerspaceGetData, userInfo.email, userInfo.lanId]);

  // hide the register button for A ids and already registered users
  useEffect(() => {
    setTimeout(() => {
      const carouselBtns = document.querySelectorAll('.courtcards button');

      carouselBtns.forEach((el) => {
        if (el.textContent === 'registerregister' && userInfo.lanId.split('')[0].toLowerCase() !== 'z') {
          el.style.display = 'none';
        }
        if (el.textContent === 'uploadupload' && userInfo.lanId.split('')[0].toLowerCase() !== 'z') {
          el.style.display = 'none';
        }
      });
    }, 2000);

    setTimeout(() => {
      const allRegBtns = document.querySelectorAll('.register_button button');
      if (allRegBtns && allRegBtns.length > 0 && userInfo.lanId.split('')[0].toLowerCase() !== 'z') {
        allRegBtns.forEach((el) => {
          el.style.display = 'none';
        });
      }
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function IsValidMobileNo(params) {
    let regex = /^[6-9][0-9]{9}$/;
    if (params.includes('XXXXXX')) {
      return true;
    } else if (!regex.test(params)) {
      return false;
    } else {
      return true;
    }
  }

  function handleChange(eventName, value) {
    if (_.find(registeredInfo, { eventName: eventName })) {
      const infoToBeUpdated = _.find(registeredInfo, { eventName: eventName });
      infoToBeUpdated['eventName'] = eventName;
      infoToBeUpdated['subEvent'] = value;

      const tempRegisteredInfo = [...registeredInfo];
      const registeredInfoWOPrevInfo = tempRegisteredInfo.filter(function (info) {
        return info.eventName !== eventName;
      });
      registeredInfoWOPrevInfo.push(infoToBeUpdated);
      setRegisteredInfo([...registeredInfoWOPrevInfo]);
    } else {
      const tempObj = {
        eventName: eventName,
        subEvent: value,
      };
      setRegisteredInfo([...registeredInfo, tempObj]);
    }
  }
  function handleSubmit(e) {
    e.preventDefault();
    const payload = {
      lanId: userInfo.lanId,
      name: userInfo.fullName,
      email: userInfo.email,
      mobile: mobileNumber,
      bookedSessions: registeredInfo,
    };
    saveEventRegistration(payload);
  }

  function handleClosePopUp() {
    refetch();
    setRegisteredInfo([]);
    setMobileNumber('');
    setIsReqSuccess(false);
    handleClose();
  }
  return (
    <Dialog
      onClose={handleClosePopUp}
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '20px',
        },
      }}
    >
      <div style={userStyles.closeBtnContainer}>
        <button onClick={handleClosePopUp} aria-label='close' style={userStyles.closeBtn}>
          <CloseIcon sx={{ fontSize: '25px', mt: '5px', cursor: 'pointer', color: '#666' }} onClick={handleClosePopUp} />
        </button>
      </div>
      <DialogContent sx={{ padding: { sm: '40px', xs: '0 20px 20px 20px' } }}>
        {!isReqSuccess && !isExistingUser ? (
          <form onSubmit={handleSubmit}>
            <FormControl>
              <Typography variant='h6' component='h6' className='h4-fonts' sx={{ color: '#000', marginTop: { xs: '20px', sm: 0 } }}>
                {`Welcome ${userInfo.fullName},`}
              </Typography>
              <FormLabel
                id='mobile'
                sx={{
                  color: '#333333 !important',
                  fontWeight: 'bold',
                  mt: '10px',
                  fontSize: { xs: '15px' },
                }}
              >
                Mobile number
              </FormLabel>
              <TextField
                required
                value={mobileNumber}
                onChange={(e) => {
                  setMobileNumber(e.target.value);
                  if (!IsValidMobileNo(e.target.value)) setMobileError(true);
                  else setMobileError(false);
                }}
                label='Mobile number'
                variant='outlined'
                size='small'
                sx={{
                  width: '50%',
                  fontSize: { xs: '15px' },
                  m: '10px 0',
                  '& .Mui-focused': {
                    outline: '2px #666666',
                    color: '#666666 !important',
                    outlineOffset: 2,
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#666666 !important',
                    },
                  },
                }}
                inputProps={{
                  maxLength: 10,
                }}
              />
              {mobileError && (
                <Typography component='span' sx={{ color: '#cc0000' }}>
                  please enter a valid mobile number
                </Typography>
              )}
              <Typography
                variant='h6'
                component='h6'
                className='h4-fonts'
                sx={{ color: '#333333', fontWeight: 'bold', marginBottom: '10px', fontSize: { xs: '15px' } }}
              >
                Please choose upto one area of interest <br /> from each of the categories below
              </Typography>

              {events.map(({ eventName, subEvents }, index) => (
                <div key={index}>
                  <FormLabel
                    id='event'
                    sx={{
                      fontSize: { xs: '15px' },
                      color: '#CC0000 !important',
                      fontWeight: 'bold',
                    }}
                  >
                    {eventName}
                  </FormLabel>
                  <RadioGroup
                    onChange={(e) => handleChange(eventName, e.target.value)}
                    aria-labelledby='demo-radio-buttons-group-label'
                    name='radio-buttons-group'
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      '&:last-child': {
                        marginBottom: '0',
                      },
                      '& .Mui-checked': {
                        color: '#cc0000',
                      },
                    }}
                  >
                    {subEvents.map((subEvent, index) => (
                      <FormControlLabel
                        key={subEvent + index}
                        value={subEvent}
                        control={<Radio key={index} sx={{ color: '#666 !important', fontSize: { xs: '15px' } }} />}
                        label={subEvent}
                        sx={{ fontSize: { xs: '15px !important' } }}
                      />
                    ))}
                  </RadioGroup>
                  {index < events.length - 1 && <Divider orientation='horizontal' sx={{ m: '10px' }} />}
                </div>
              ))}

              <Button
                variant='contained'
                type='submit'
                disabled={mobileError || !(registeredInfo.length > 0 && !isReqSuccess && !isExistingUser)}
                sx={{
                  mt: 2,
                  backgroundColor: '#CC0000',
                  borderRadius: '20px',
                  textTransform: 'lowercase',
                  width: '20%',
                  '&:hover': {
                    backgroundColor: '#CC0000',
                  },
                }}
              >
                {saveInProgress ? <CircularProgress size={20} sx={{ color: '#FFF' }} /> : 'submit'}
              </Button>
              {errorResponse && (
                <Typography variant='h4' component='h3' className='h4-fonts' sx={{ color: '#CC0000', fontSize: '20px !important', mt: '10px' }}>
                  Something went wrong, please try again later.
                </Typography>
              )}
            </FormControl>
          </form>
        ) : (
          <>
            {isExistingUser && !isReqSuccess && (
              <>
                <Typography variant='h6' component='h5' className='h4-fonts'>
                  Hey, thanks for your interest. You've already registered - stay tuned for updates in your Inbox.
                </Typography>
              </>
            )}
            <>
              {isReqSuccess && (
                <>
                  {' '}
                  <Typography
                    variant='h3'
                    component='h5'
                    className='h4-fonts'
                    sx={{ marginBottom: '10px', fontWeight: 'bold', paddingTop: { xs: '20px' }, fontSize: { xs: '37px' } }}
                  >
                    <span style={{ color: '#CC0000' }}>Thank you</span> <br /> for signing up.
                  </Typography>
                  <Typography variant='h6' component='h5' className='h4-fonts'>
                    Please attend the briefing call on March 15<sup>th</sup> for more. Check your calendar for a Zoom link.
                  </Typography>
                </>
              )}
            </>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RegisterForEvent;
