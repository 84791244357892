export const heroBanner =
  "https://gfc.target.com/tiiam/tiiam22/hero_banner_p2.jpg";
export const mobileHeroBanner =
  "https://gfc.target.com/tiiam/tiiam22/mobile_hero_banner_p2.png";
export const logo = "https://gfc.target.com/tiiam/tiiam22/logo.png";
export const faqsBanner = "https://gfc.target.com/tiiam/tiiam22/faq_banner.jpg";
export const faqsMobileBanner =
  "https://gfc.target.com/tiiam/tiiam22/faq_mobile_banner.jpg";
export const NoCopybanner =
  "https://gfc.target.com/tiiam/tiiam22/NoCopybanner.jpg";
export const mobile_speaker =
  "https://gfc.target.com/tiiam/tiiam22/mobile_speaker.jpg";
export const speaker1 = "https://gfc.target.com/tiiam/tiiam22/speaker1_new.jpg";
export const speaker2 = "https://gfc.target.com/tiiam/tiiam22/speaker2_new.jpg";
export const speaker3 = "https://gfc.target.com/tiiam/tiiam22/speaker3_new.jpg";
export const mobile_speaker1 =
  "https://gfc.target.com/tiiam/tiiam22/speaker_mobile1.jpg";
export const mobile_speaker2 =
  "https://gfc.target.com/tiiam/tiiam22/speaker_mobile2.jpg";
export const mobile_speaker3 =
  "https://gfc.target.com/tiiam/tiiam22/speaker_mobile3.jpg";
export const hero_banner_ipad =
  "https://gfc.target.com/tiiam/tiiam22/hero_banner_ipad.jpg";
export const windows_hero_banner =
  "https://gfc.target.com/tiiam/tiiam22/windows_hero_banner.jpg";
export const register_bg =
  "https://gfc.target.com/tiiam/tiiam22/register_bg.png";
export const hover_1 = "https://gfc.target.com/tiiam/tiiam22/hover-1.png";
export const hover_2 = "https://gfc.target.com/tiiam/tiiam22/hover-2.png";
export const hover_3 = "https://gfc.target.com/tiiam/tiiam22/hover-3.png";
export const accordion_bg =
  "https://gfc.target.com/tiiam/tiiam22/accordion_bg.png";
export const hero_animation =
  "https://gfc.target.com/tiiam/tiiam22/hero_animation.mp4";
export const hero_animation_v2 =
  "https://gfc.target.com/tiiam/tiiam22/hero_animation_v2.mp4";
export const instagram = "https://gfc.target.com/tiiam/tiiam22/instagram.png";
export const hero_mobile_p3 =
  "https://gfc.target.com/tiiam/tiiam22/hero_mobile_p3.jpg";
export const windows_hero_banner_p3 =
  "https://gfc.target.com/tiiam/tiiam22/windows_hero_banner_p3.jpg";
export const gallery_banner_desktop =
  "https://gfc.target.com/tiiam/tiiam22/Gallary_Banner_Desktop.png";
export const gallery_banner_mobile =
  "https://gfc.target.com/tiiam/tiiam22/Gallary_Banner_mobile.png";
export const whiteCrossSvg =
  "https://gfc.target.com/tiiam/tiiam22/white_cross.svg";

export const closeIcon = "https://gfc.target.com/tiiam/tiiam22/white_cross.svg";
