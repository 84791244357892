import { AppBar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/Logo_TIIAM2024.png';
import React, { useState, useEffect } from 'react';
// import { HashLink } from "react-router-hash-link";
import { useTenantContext, useGetConfigQuery } from 'enterprise-stencil';

const drawerWidth = 240;
// const navItems = ["Things to do", "Gallery", "FAQs"];

const Header = (props) => {
  const { tenantId } = useTenantContext();
  const tab = useMediaQuery((theme) => theme.breakpoints.between(768, 1023));
  const windowsLaptop = useMediaQuery((theme) => theme.breakpoints.between(1024, 1700));
  const navigate = useNavigate();
  const location = useLocation();
  const [headerData, setHeaderData] = useState(null);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { data: menuData } = useGetConfigQuery(
    {
      tenantId,
    },
    { skip: !tenantId }
  );

  useEffect(() => {
    if (menuData) {
      const menu = menuData?.filter((item) => item.name === 'header');
      setHeaderData(menu[0]?.published_value);
    }
  }, [menuData]);

  useEffect(() => {});

  const userStyles = {
    linksBox: {
      display: { xs: 'none', sm: 'flex' },
      justifyContent: 'flex-end',
      marginRight: '45px',
      gap: '30px',
      minWidth: tab ? '50%' : windowsLaptop ? '40%' : '30%',
      '& a': {
        color: '#333333',
        textDecoration: 'none',
        fontFamily: '',
        '&:hover': {
          // textDecoration: 'underline !important',
        },
        '&:focus-visible': {
          outline: '2px dotted #666666',
          outlineOffset: 2,
        },
      },
    },
    activeLink: {
      backgroundColor: 'red',
    },
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'left' }}>
      <Divider />
      <List>
        {headerData &&
          headerData.map((item) => (
            <ListItem key={item.name} disablePadding>
              <ListItemButton sx={{ textAlign: 'left' }}>
                <ListItemText>
                  <>
                    <Link to={item.slug} key={item.name} style={{ textDecoration: 'none', color: '#333333' }}>
                      {item.name}
                    </Link>
                    {/* ) : (
                      <HashLink
                        to={`/#${item.slug}`}
                        key={item.name}
                        style={{ textDecoration: "none", color: "#333333" }}
                        smooth
                      >
                        {item.name}
                      </HashLink>
                    ) */}
                  </>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', mb: '50px', textAlign: 'left' }}>
      <AppBar component='nav' sx={{ zIndex: 100 }}>
        <Toolbar
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            bgcolor: '#FFF',
            color: '#000',
          }}
        >
          <Box sx={{ display: { sm: 'none' } }}>
            <Link to='/'>
              <img src={logo} width='40%' alt={'TIIAM2024'} onClick={() => navigate('/')} style={{ cursor: 'pointer', paddingLeft: '13%' }} />
            </Link>
          </Box>
          <IconButton color='inherit' aria-label='open drawer' edge='end' onClick={handleDrawerToggle} sx={{ display: { sm: 'none' } }}>
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, marginLeft: { md: '3%' } }}>
            <Link to='/'>
              <img
                src={logo}
                width={tab ? '33%' : windowsLaptop ? '25%' : '15%'}
                alt={'TIIAM2024'}
                onClick={() => navigate('/')}
                style={{ cursor: 'pointer', marginLeft: '30px' }}
              />
            </Link>
          </Typography>
          <Box sx={userStyles.linksBox}>
            {headerData &&
              headerData.map((item, index) => (
                <React.Fragment key={`nav_${index}`}>
                  {item.slug.includes('https') ? (
                    <Link
                      to={item.slug}
                      key={item.name}
                      target='_blank'
                      style={{
                        borderBottom: location.pathname === item.slug ? '2px solid #CC0000' : 'none',
                        // fontFamily:
                        //   location.pathname === `/${item.toLowerCase()}`
                        //     ? "TargetBold"
                        //     : "TargetLight",
                        paddingBottom: '10px',
                      }}
                    >
                      {item.name}
                    </Link>
                  ) : (
                    <Link
                      to={item.slug}
                      key={item.name}
                      style={{
                        borderBottom: location.pathname === item.slug ? '2px solid #CC0000' : 'none',
                        // fontFamily:
                        //   location.pathname === `/${item.toLowerCase()}`
                        //     ? "TargetBold"
                        //     : "TargetLight",
                        paddingBottom: '10px',
                      }}
                    >
                      {item.name}
                    </Link>
                  )}
                  {/* //   <HashLink
                  //     to={`/#${item.slug}`}
                  //     key={item.name}
                  //     style={{
                  //       borderBottom:
                  //         location.pathname === item.slug
                  //           ? "2px solid #CC0000"
                  //           : "none",
                  //       // fontFamily:
                  //       //   location.hash === `#${item.toLowerCase()}`
                  //       //     ? "TargetBold"
                  //       //     : "TargetLight",
                  //       paddingBottom:
                  //         location.pathname === item.slug ? "10px" : "none",
                  //     }}
                  //     smooth
                  //   >
                  //     {item.name}
                  //   </HashLink>
                  // ) */}
                </React.Fragment>
              ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component='nav'>
        <Drawer
          anchor='right'
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              right: 0,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;
