import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetAllPhotoscapesQuery } from '../../api/photoscapes';
import Images from './RenderGallery';
import UploadPhoto from '../UploadPhoto/UploadPhoto';

const PhotoscapesGallery = () => {
  const [allPhotoscapes, setAllPhotoscapes] = useState([]);
  const userInfo = useSelector((state) => state.session.userInfo);
  const [openUploadPopUp, setOpenUploadPopUp] = useState(false);

  const { data: photoscapesData } = useGetAllPhotoscapesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      skip: !userInfo.lanId,
    }
  );
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenUploadPopUp(false);
  };
  useEffect(() => {
    if (photoscapesData) {
      const photosdata = [...photoscapesData];
      setAllPhotoscapes(photosdata);
    }
  }, [photoscapesData]);

  useEffect(() => {
    setTimeout(() => {
      const psGalleryBtn = document.querySelector('.uploadinps button');
      console.log('psGalleryBtn ', psGalleryBtn);
      psGalleryBtn?.addEventListener('click', function () {
        setOpenUploadPopUp(true);
      });

      if (psGalleryBtn?.textContent === 'uploadupload' && userInfo.lanId.split('')[0].toLowerCase() !== 'z') {
        psGalleryBtn.style.display = 'none';
      }
    }, 2500);
  });
  return (
    <>
      <Images images={allPhotoscapes} />
      <UploadPhoto open={openUploadPopUp} handleClose={handleClose} />
    </>
  );
};

export default PhotoscapesGallery;
