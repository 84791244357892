import FaqsItem from './FaqsItem';
import faqs from '../../assets/json/faqs.json';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTheme } from '@emotion/react';

const Faqs = () => {
  const theme = useTheme();
  const classes = {
    goldLine: {
      width: '6%',
      display: 'block',
      margin: '1 auto',
      border: '2px solid #be854c',
      backgroundColor: '#be854c',
      [theme.breakpoints.down('md')]: {
        width: '20%',
      },
    },
    faqContainer: {
      width: '80%',
      margin: '10px auto',
      pb: 3,
      [theme.breakpoints.down('md')]: {
        margin: '0',
        width: '100%',
      },
      '& .MuiPaper-root': {
        boxShadow: 'none',
      },
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Box sx={classes.faqContainer}>
        {faqs.map((faq, index) => {
          if (faq.heading) {
            return (
              <Typography variant='h5' sx={{ textAlign: 'left', paddingLeft: '16px', fontWeight: 'bold', color: '#CC0000' }}>
                {faq.name}
              </Typography>
            );
          } else {
            return <FaqsItem title={faq.question} desc={faq.answer} />;
          }
        })}
      </Box>
    </>
  );
};

export default Faqs;
