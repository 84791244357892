import React from "react";
import { Box } from "@mui/material";
import FlipCountdown from "@rumess/react-flip-countdown";
import timerBackgroundDesktop from "../assets/images/CountDown_Large.png";
import timerBackgroundTablet from "../assets/images/CountDown_medium.png";
import timerBackgroundMobile from "../assets/images/CountDown_small.png";

const LiveCounter = () => {
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          backgroundImage: {
            lg: `url(${timerBackgroundDesktop})`,
            md: `url(${timerBackgroundTablet})`,
            sm: `url(${timerBackgroundTablet})`,
            xs: `url(${timerBackgroundMobile})`,
          },
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: { xl: 360, lg: 300, md: 250, sm: 180, xs: 85 },
        }}
      >
        <FlipCountdown
          dayTitle="DAYS"
          hourTitle="HOURS"
          minuteTitle="MINUTES"
          secondTitle="SECONDS"
          hideYear
          hideMonth
          theme="light"
          endAt={"2023-04-28 10:00:00"}
        />
      </Box>
    </>
  );
};

export default LiveCounter;
