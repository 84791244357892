import { Dialog, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';

const Popup = ({ handleClose, open, heading, message, isError }) => {
  const userStyles = {
    dialogBody: {
      //   margin: '40px',
      backgroundColor: isError ? '#CC0000' : '#198754',
    },
    closeBtnContainer: {
      position: 'absolute',
      right: '0',
    },
    heading: {
      textAlign: 'center',
      fontFamily: 'HelveticaForTargetBold',
      fontSize: '30px',
      color: isError ? '#FFF' : '#FFF',
      fontWeight: 'bold',
    },
    body: {
      fontSize: '30px',
      textAlign: 'center',
      fontFamily: 'HelveticaforTarget-Light',
      color: isError ? '#FFF' : '#FFF',
    },
    closeBtn: {
      backgroundColor: 'transparent',
      border: 'none',
      '&:focus-visible': {
        outline: '2px dotted #666666',
        outlineOffset: 2,
      },
    },
  };
  let textInput = null;
  useEffect(() => {
    textInput && textInput.focus();
  });
  return (
    <Dialog onClose={handleClose} open={open} sx={{ borderRadius: '30px', border: '1px solid #CC0000' }}>
      <div style={userStyles.closeBtnContainer}>
        <button
          ref={(button) => {
            textInput = button;
          }}
          onClick={handleClose}
          aria-label='close'
          style={userStyles.closeBtn}
        >
          <CloseIcon sx={{ fontSize: 40, cursor: 'pointer', color: '#FFF' }} onClick={handleClose} />
        </button>
      </div>
      <div style={userStyles.dialogBody}>
        <div style={{ padding: '40px' }}>
          {heading ? (
            <Typography variant='h2' sx={userStyles.heading}>
              {heading}
            </Typography>
          ) : null}
          <Typography sx={userStyles.body}>{message}</Typography>
        </div>
      </div>
    </Dialog>
  );
};

export default Popup;
