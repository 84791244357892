/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from 'react-router-dom';
import { Grid, IconButton, Modal, Box, Button, CardMedia } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { closeIcon } from '../../assets/images/SiteImages';

const Images = ({ images }) => {
  images = images.filter((image) => image.status === 'published');
  const imagesArray = [];
  images.forEach((imgObj) => {
    imgObj.photos.forEach((photo) => {
      const tempObj = { ...imgObj };
      tempObj['src'] = photo['photoUrl'];
      tempObj['alt'] = photo['descritption'];
      tempObj['compressed'] = photo['compressedPhotoUrl'];
      delete tempObj['photos'];
      imagesArray.push(tempObj);
    });
  });
  const [isModalOpen, setModel] = useState(false);
  const [url, setURL] = useState('');
  const [altText, setAltText] = useState('');
  const [name, setName] = useState('');
  const [pyramid, setPyramid] = useState('');
  const [index, setIndex] = useState();
  const location = useLocation();

  const handleClick = (image, i) => {
    setIndex(i);
    setURL(image.src);
    setAltText(image.alt);
    setName(image.name);
    setPyramid(image.pyramid);

    setModel(true);
  };

  const prevAsset = () => {
    if (index === 0) {
      const image = imagesArray[imagesArray.length - 1];
      setURL(image.src);
      setAltText(image.alt);
      setName(image.name);
      setPyramid(image.pyramid);
      setIndex(imagesArray.length - 1);
      return;
    }
    const i = index - 1;
    const image = imagesArray[i];
    setURL(image.src);
    setAltText(image.alt);
    setIndex(i);
    setName(image.name);
    setPyramid(image.pyramid);
  };

  const nextAsset = () => {
    if (index === imagesArray.length - 1) {
      const image = imagesArray[0];
      setURL(image.src);
      setAltText(image.alt);
      setName(image.name);
      setPyramid(image.pyramid);
      setIndex(0);
      return;
    }
    const i = index + 1;
    const image = imagesArray[i];
    setURL(image.src);
    setAltText(image.alt);
    setName(image.name);
    setPyramid(image.pyramid);
    setIndex(i);
  };

  useEffect(() => {
    const onArrowKeyPress = (event) => {
      const leftArrow = 37;
      const rightArrow = 39;

      if (event.keyCode === leftArrow) {
        prevAsset();
      } else if (event.keyCode === rightArrow) {
        nextAsset();
      }
    };
    document.addEventListener('keydown', onArrowKeyPress);
    return () => document.removeEventListener('keydown', onArrowKeyPress);
  }, [nextAsset, prevAsset]);

  const mobile = useMediaQuery('(max-width:755px)');
  const tab = useMediaQuery('(max-width:1008px)');
  const desktop = useMediaQuery('(max-width:1494px)');

  return (
    <>
      <Box
        container={'true'}
        sx={{
          columnCount: mobile ? 2 : tab ? 3 : desktop ? 4 : 5,
          paddingBottom: 2,
          width: location.pathname.slice(1) === 'gallery' ? '80%' : '97%',
          margin: '20px',
          columnGap: 0,
        }}
        role='dialog'
        aria-label='slideshow of tiiam 2024 photoscapes '
      >
        {!isModalOpen &&
          imagesArray.map((image, i) => {
            return (
              <>
                <Button
                  aria-label={image.alt}
                  key={image.src}
                  sx={{
                    position: 'relative',
                    margin: '0px 1px 2px',
                    display: 'flex',
                    background: '#ffffff',
                    '&:hover': {
                      opacity: 0.8,
                      '& $expandIcon': {
                        display: 'block !important',
                        zIndex: '100',
                      },
                      '.search-icon': {
                        display: 'block',
                      },
                    },
                    border: 'none',
                    padding: 0,
                    '&:focus-visible': {
                      opacity: 0.8,
                      outline: '2px dotted #666666',
                      outlineOffset: 2,
                    },
                  }}
                  onClick={() => handleClick(image, i)}
                >
                  <img src={image.compressed ? image.compressed : image.src} alt={image.alt} style={{ width: '100%', cursor: 'pointer' }} />
                  <SearchIcon
                    fontSize='large'
                    className='search-icon'
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      margin: 'auto',
                      display: 'none',
                      color: '#fff',
                    }}
                  />
                </Button>
              </>
            );
          })}

        <Modal
          open={isModalOpen}
          onClose={() => setModel(false)}
          role='dialog'
          aria-label='images of tiiam 2024 photoscapes slideshow'
          sx={{ backgroundColor: '#000000' }}
        >
          <div>
            <Grid
              item
              sx={{
                height: '40px',
                fontFamily: 'HelveticaforTargetRegular',
                fontSize: 14,
                fontWeight: 'normal',
                color: '#e5e5e5',
                '&:focus': {
                  outline: 'none',
                },
              }}
            >
              <div
                style={{
                  display: 'flex',
                  margin: '15px',
                }}
              >
                &nbsp;{' '}
                <h2
                  style={{
                    fontSize: '16px',
                    margin: 0,
                    fontFamily: 'HelveticaForTarget',
                  }}
                >
                  {altText}
                  <span style={{ padding: '0 5px' }}>|</span>
                </h2>
                <h2
                  style={{
                    fontSize: '16px',
                    margin: 0,
                    fontFamily: 'HelveticaForTarget',
                  }}
                >
                  {name} <span style={{ padding: '0 5px', textTransform: 'capitalize' }}>|</span>
                  {pyramid.toLowerCase()}
                </h2>
              </div>

              <button
                onClick={() => setModel(false)}
                aria-label='close'
                style={{
                  background: `url(${closeIcon}) no-repeat`,
                  float: 'right',
                  right: '2%',
                  top: '30px',
                  position: 'absolute',
                  fontSize: '40px !important',
                  cursor: 'pointer',
                  width: '20px',
                  height: '20px',
                  border: 'none',
                }}
              ></button>
            </Grid>

            <Grid container>
              <Grid
                style={{
                  backgroundColor: '#000000',
                  height: '100vh',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                item
                xs={12}
                aria-label={altText}
              >
                <IconButton
                  aria-label='previous image'
                  style={{
                    fontSize: 50,
                    color: '#FFFFFF',
                    background: 'rgba(0, 0, 0, 0.5)',
                    backgroundImage: 'none',
                    height: '60px',
                    width: '60px',
                    margin: '10px',
                  }}
                  onClick={() => prevAsset()}
                >
                  <ChevronLeft fontSize='inherit' />
                </IconButton>
                <CardMedia
                  component='img'
                  image={url}
                  alt={altText}
                  sx={{
                    height: '100vh',
                    width: '90%',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    objectFit: 'contain',
                  }}
                />
                <IconButton
                  aria-label='next image'
                  style={{
                    fontSize: 50,
                    color: '#FFFFFF',
                    background: 'rgba(0, 0, 0, 0.5)',
                    backgroundImage: 'none',
                    height: '60px',
                    width: '60px',
                    margin: '10px',
                  }}
                  onClick={() => nextAsset()}
                >
                  <ChevronRight fontSize='inherit' />
                </IconButton>
              </Grid>
            </Grid>
          </div>
        </Modal>
      </Box>
    </>
  );
};
export default Images;
