/* eslint-disable react-hooks/exhaustive-deps */
import { Button, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import { useAdmitUserMutation, useGetTotalAdmissionsQuery } from '../../api/outerSpaces';
import Popup from '../Utils/Popup';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const OuterspacesAdmission = () => {
  const userInfo = useSelector((state) => state.session.userInfo);
  const [cardNo, setCardNo] = useState('');
  const [lanId, setLanId] = useState('');
  // const [sessionName, setSessionName] = useState();
  const sessionName = new Date().getDate() === 25 ? 'ricky' : new Date().getDate() === 26 ? 'sania' : '';

  const [tryOtherMethods, setTryOtherMethods] = useState(false);
  const [admitUser] = useAdmitUserMutation();
  const { data: totalAdmissions, refetch } = useGetTotalAdmissionsQuery({ sessionName: sessionName }, { skip: !userInfo.lanId });
  // pop up variables
  const [popupData, setPopupData] = useState({ openPopup: false });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setPopupData({ openPopup: false });
  };
  // end of pop up variables

  function recordTheScan(e) {
    if (e.keyCode === 13) {
      const payload = {};
      payload['card_no'] = cardNo;
      payload['session_name'] = sessionName;
      if (!sessionName) {
        setPopupData({
          popupHeading: 'Error',
          popupMsg: 'choose session',
          openPopup: true,
          is_error: true,
        });
        refetch();
        return;
      }
      if (!cardNo) {
        setPopupData({
          popupHeading: 'Error',
          popupMsg: 'Please enter card number',
          openPopup: true,
          is_error: true,
        });
        refetch();
        return;
      }
      admitUser(payload).then((res) => {
        const name = res.data.record?.email.split('@')[0].replace('.', ' ');
        if (res.data.record === null) {
          setPopupData({
            popupHeading: 'Error',
            popupMsg: 'Employee data not found / not registered,please try with Z id',
            openPopup: true,
            is_error: true,
          });
          refetch();
          return;
        }
        if (res.data.approved) {
          setPopupData({
            popupHeading: 'Success',
            popupMsg: `Welcome to outerspace ${name}`,
            openPopup: true,
            is_error: false,
          });
          refetch();
        } else {
          setPopupData({
            popupHeading: 'Sorry',
            popupMsg: `${name} already admitted`,
            openPopup: true,
            is_error: true,
          });
          refetch();
        }
      });
      setCardNo('');
    }
  }
  function handleLanIdCall() {
    const payload = {};
    payload['lan_id'] = lanId.toLowerCase();
    payload['session_name'] = sessionName;
    if (!sessionName) {
      setPopupData({
        popupHeading: 'Error',
        popupMsg: 'choose session',
        openPopup: true,
        is_error: true,
      });
      refetch();
      return;
    }
    if (!lanId) {
      setPopupData({
        popupHeading: 'Error',
        popupMsg: 'Please enter lanid',
        openPopup: true,
        is_error: true,
      });
      refetch();
      return;
    }
    const isContrator = payload.lan_id.split('')[0] === 'A';
    if (isContrator) {
      setPopupData({
        popupHeading: 'Error',
        popupMsg: 'Not a valid card',
        openPopup: true,
        is_error: true,
      });
      refetch();
      return;
    }
    admitUser(payload).then((res) => {
      const name = res.data.record?.email.split('@')[0].replace('.', ' ');
      if (res.data.record === null) {
        setPopupData({
          popupHeading: 'Error',
          popupMsg: 'Employee data not found / not registered',
          openPopup: true,
          is_error: true,
        });
        refetch();
        return;
      }

      if (res.data.approved) {
        setPopupData({
          popupHeading: '',
          popupMsg: `Welcome to outerspace ${name}`,
          openPopup: true,
          is_error: false,
        });
        refetch();
      } else {
        setPopupData({
          popupHeading: 'Sorry',
          popupMsg: `${name} already admitted`,
          openPopup: true,
          is_error: true,
        });
        refetch();
      }
      setLanId('');
    });
  }

  return (
    <>
      <Popup open={popupData.openPopup} handleClose={handleClose} heading={popupData.popupHeading} message={popupData.popupMsg} isError={popupData.is_error} />
      {popupData.is_error && (
        <audio autoPlay>
          <source src='https://gfc.target.com/tiiam/tiiam23/Error_message_Tune.mp3' type='audio/ogg' />
          <source src='horse.mp3' type='audio/mpeg' />
          Your browser does not support the audio element.
        </audio>
      )}

      <Grid
        container
        style={{ marginTop: '5%', backgroundColor: '#F2E9E4' }}
        sx={{ minHeight: '80vh', width: '50%', margin: '0 auto', display: 'flex', flexDirection: 'column' }}
        alignItems='center'
        // justifyContent='center'
      >
        <img src={'https://gfc.target.com/tiiam/tiiam23/swipe_ur_id_header.png'} width={'25%'} alt='ad' style={{ marginTop: '20px' }} />
        <div>
          {/* <FormControl>
            <FormLabel>Choose a session</FormLabel>
            <RadioGroup name='radio-buttons-group' onChange={(e) => setSessionName(e.target.value)}>
              <FormControlLabel value='ricky' control={<Radio />} label='Ricky' />
              <FormControlLabel value='sania' control={<Radio />} label='Sania' />
            </RadioGroup>
          </FormControl> */}
          <TextField
            autoFocus
            style={{ display: tryOtherMethods ? 'none' : 'block', marginTop: '20px' }}
            id='standard-basic'
            label='Card number'
            variant='standard'
            value={cardNo}
            onChange={(e) => setCardNo(e.target.value)}
            onKeyDown={(e) => recordTheScan(e)}
          />
        </div>
        <div>
          <FormControlLabel
            control={<Switch />}
            label='Try with Z id'
            onClick={() => {
              setTryOtherMethods(!tryOtherMethods);
            }}
          />
        </div>
        {tryOtherMethods ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField autoFocus id='standard-basic' label='Z ID' variant='standard' value={lanId} onChange={(e) => setLanId(e.target.value)} />
            <Button variant='outlined' onClick={handleLanIdCall} sx={{ mt: '10px' }}>
              GO
            </Button>
          </div>
        ) : null}
        <h3>Total admitted members : {totalAdmissions && totalAdmissions.length}</h3>
      </Grid>
    </>
  );
};

export default OuterspacesAdmission;
