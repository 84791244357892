import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AgendaDay1 from '../Agenda/AgendaDay1';
import AgendaDay2 from '../Agenda/AgendaDay2';
import AgendaDay3 from '../Agenda/AgendaDay3';
// import TempAgenda from '../Agenda/TempAgenda';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const WhatsInStore = ({ tabNames, tabNamePanels, isSubTab = false }) => {
  const [value, setValue] = React.useState(1);
  const tabs = tabNames ? tabNames : ['April 16', 'April 17', 'April 18'];
  const tabPanels = tabNamePanels ? tabNamePanels : [<AgendaDay1 />, <AgendaDay2 />, <AgendaDay3 />];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        width: '100%',
        my: '20px',

        '& button': {
          fontSize: '20px !important',
        },
        '& .MuiTabs-flexContainer button.Mui-selected': {
          fontSize: '20px !important',
          color: '#cc0000',
        },
      }}
    >
      <Box sx={{ paddingLeft: '88px', paddingBottom: isSubTab && '20px', backgroundColor: isSubTab && '#f9eaea' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          TabIndicatorProps={{
            style: {
              display: !isSubTab && 'none',
              backgroundColor: isSubTab && '#CC0000',
            },
          }}
          sx={{
            textTransform: 'capitalize !important',
            margin: '0 10px',
            '& .Mui-selected': {
              border: !isSubTab && '1px solid #CC0000',
              padding: !isSubTab && '10px 30px',
              borderRadius: !isSubTab && '50px',
            },
            '& .MuiButtonBase-root': {
              //   padding: '0',
            },
          }}
        >
          {tabs.map((tab, tIndex) => (
            <Tab
              sx={{
                fontSize: '20px !important',
                textTransform: 'capitalize !important',
              }}
              label={tab}
              {...a11yProps(tIndex)}
            />
          ))}
        </Tabs>
      </Box>
      {tabPanels.map((tabPanel, tpIndex) => (
        <CustomTabPanel value={value} index={tpIndex}>
          {tabPanels[tpIndex]}
        </CustomTabPanel>
      ))}
    </Box>
  );
};

export default WhatsInStore;
