import { useEffect, useState, useRef } from "react";
import {
  Button,
  Box,
  FormControl,
  TextareaAutosize,
  MenuItem,
  Select,
  Typography,
  InputLabel,
  TextField,
  FormHelperText,
  useMediaQuery,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { useSelector } from "react-redux";
import formBG from "../../assets/images/BG_Form.png";
import { useGetgtkyQuery, usePostgtkyMutation } from "../../api/gtky";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { expInWord, pyramid } from "../../Shared/Constants";
import { useNavigate } from "react-router-dom";

const AddGTKY = () => {
  const defaultData = {
    name: "",
    pyramid: "",
    exp_in_number: "",
    exp_in_word: "",
    description: "",
    fun_facts: "",
  };
  const [gtkyFormData, setGTKYFormData] = useState(defaultData);
  const [errors, setErrors] = useState({
    pyramidError: "",
    exp_in_numberError: "",
    exp_in_wordError: "",
    descriptionError: "",
    fun_factsError: "",
    fileError: "",
  });

  const userInfo = useSelector((state) => state.session.userInfo);

  const { data: gtkyData, isLoading: gtkyDataInprogress } = useGetgtkyQuery(
    {},
    {
      skip: !userInfo.lanId,
    }
  );

  const fileRef = useRef(null);
  //   const [show, setShow] = useState(false);
  const [attachment, setAttachment] = useState();
  const [textAreaCount, setTextAreaCount] = useState(0);
  const [funfactCount, setFunfactCount] = useState(0);
  const [openSuccess, setOpenSuccess] = useState(false);
  const navigate = useNavigate();
  const [errorResponse, setErrorResponse] = useState(false);
  const [GTKYData, setGTKYData] = useState(false);
  const mobile = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const [
    saveGTKYFormData,
    { data: responseData, isLoading: saveInProgress, isError: responseError },
  ] = usePostgtkyMutation();

  useEffect(() => {
    if (userInfo) {
      setGTKYFormData({ ...gtkyFormData, name: userInfo.fullName });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    // setShow(true);
    if (responseData) {
      setGTKYFormData(defaultData);
      setAttachment(null);
      setOpenSuccess(true);
    }
    if (responseError) {
      setGTKYFormData(defaultData);
      setAttachment(null);
      setErrorResponse(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData, responseError]);

  useEffect(() => {
    if (gtkyData) {
      setGTKYData(gtkyData);
    }
  }, [gtkyData]);

  const checkUser = GTKYData
    ? GTKYData.filter((item) => item.email === userInfo.email)
    : "";

  const handleselectedFile = (event) => {
    if (event.target.files[0] && event.target.files[0].size < 5000000) {
      setAttachment(event.target.files[0]);
      setErrors({
        ...errors,
        fileError: "",
      });
    } else {
      setErrors({
        ...errors,
        fileError: "Please select smaller file!. file exceeded 5MB",
      });
    }
  };

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    let updated = value;
    if (name === "exp_in_number") {
      const onlyNums = value.replace(/[^0-9+]/g, "").slice(0, 2);
      updated = onlyNums;
    }
    setGTKYFormData({ ...gtkyFormData, [name]: updated });
  };

  const recalculate = (e) => {
    setTextAreaCount(e.target.value.length);
  };

  const calculateLength = (e) => {
    setFunfactCount(e.target.value.length);
  };

  const isValidForm = () => {
    const { pyramid, exp_in_number, exp_in_word, description, fun_facts } =
      gtkyFormData;
    let errorVal = { ...errors };
    if (!pyramid) {
      errorVal.pyramidError = "Please fill your pyramid name";
    } else {
      errorVal.pyramidError = "";
    }

    if (!exp_in_number) {
      errorVal.exp_in_numberError = "Please fill this field";
    } else {
      errorVal.exp_in_numberError = "";
    }

    if (!exp_in_word) {
      errorVal.exp_in_wordError = "Please select the field";
    } else {
      errorVal.exp_in_wordError = "";
    }

    if (!description) {
      errorVal.descriptionError = "Please fill this field";
    } else {
      errorVal.descriptionError = "";
    }

    if (!fun_facts) {
      errorVal.fun_factsError = "Please fill this field";
    } else {
      errorVal.fun_factsError = "";
    }

    if (!attachment) {
      errorVal.fileError = "Please upload image";
    } else {
      errorVal.fileError = "";
    }

    setErrors(errorVal);
    const isError = Object.values(errorVal).some((k) => k !== "");
    return !isError;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidForm()) {
      gtkyFormData["email"] = userInfo.email;
      gtkyFormData["designation"] = userInfo.title;
      gtkyFormData["is_approved"] = false;
      saveGTKYFormData({ ...gtkyFormData, attachment });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorResponse(false);
    setOpenSuccess(false);
    navigate("/");
  };
  return (
    <>
      {userInfo &&
        userInfo.lanId &&
        userInfo.lanId.split("")[0].toLowerCase() === "z" && (
          <Box sx={{ backgroundImage: `url(${formBG})`, minHeight: "800px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: { md: "70px", sm: 2, xs: 2 },
              }}
            >
              {gtkyDataInprogress ? (
                <CircularProgress
                  sx={{ color: "rgb(204, 0, 0)" }}
                ></CircularProgress>
              ) : checkUser && checkUser.length > 0 ? (
                <Typography
                  variant="h4"
                  component="h3"
                  className="h4-fonts"
                  sx={{ mt: 12 }}
                >
                  Looks like you've already submitted your profile! Go to{" "}
                  <a href="/">Home</a>
                </Typography>
              ) : (
                <Box sx={{ m: { md: 4, sm: 2, xs: 2 } }}>
                  <Box>
                    <TextField
                      className="body1-fonts"
                      id="name"
                      label="I am"
                      disabled={true}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        "aria-describedby": "",
                        style: {
                          fontSize: 20,
                          borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
                          paddingBottom: "10px",
                        },
                      }}
                      InputLabelProps={{
                        style: { fontSize: 20, color: "#666" },
                      }}
                      sx={{ width: { md: "100%", sm: "100%", xs: "100%" } }}
                      name="name"
                      value={gtkyFormData.name}
                      onChange={onHandleChange}
                    />
                  </Box>
                  <Box>
                    <FormControl
                      variant="standard"
                      sx={{
                        width: { md: "100%%", sm: "100%", xs: "100%" },
                        borderBottom: "2px solid black",
                        my: "25px",
                        height: "65px",
                      }}
                    >
                      <InputLabel
                        id="pyramid"
                        sx={{
                          fontSize: 20,
                          color: "#666",
                          "&.Mui-focused": {
                            color: "#666",
                          },
                        }}
                      >
                        I belong to:
                      </InputLabel>
                      <Select
                        className="body1-fonts"
                        labelId="pyramid"
                        id="pyramid"
                        name="pyramid"
                        value={gtkyFormData.pyramid}
                        label="Pyramid"
                        disableUnderline
                        onChange={onHandleChange}
                        error={errors.pyramidError}
                        sx={{
                          "& .MuiSelect-select": {
                            padding: "10px 0",
                            fontSize: 20,
                            textAlign: "left",
                            ":focus": {
                              background: "none",
                            },
                          },
                        }}
                      >
                        <MenuItem value="Select" disabled sx={{ fontSize: 20 }}>
                          <em>Select</em>
                        </MenuItem>
                        {pyramid &&
                          pyramid.length > 0 &&
                          pyramid.map((item, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={item}
                                sx={{
                                  fontSize: 20,
                                  "&.Mui-selected": {
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    ":hover": {
                                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    },
                                  },
                                }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText sx={{ color: "#d32f2f", fontSize: 16 }}>
                        {errors.pyramidError}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  <Box sx={{ display: "flex", gap: "15px" }}>
                    <FormControl
                      variant="standard"
                      sx={{
                        width: { md: "50%", sm: "100%", xs: "100%" },
                        borderBottom: "2px solid black",
                        height: "63px",
                      }}
                    >
                      <InputLabel
                        id="exp_in_word"
                        sx={{
                          fontSize: 20,
                          color: "#666",
                          "&.Mui-focused": {
                            color: "#666",
                          },
                        }}
                      >
                        I've been with Target for
                      </InputLabel>
                      <Select
                        className="body1-fonts"
                        labelId="exp_in_word"
                        id="exp_in_word"
                        name="exp_in_word"
                        value={gtkyFormData.exp_in_word}
                        label="I've been with Target for"
                        disableUnderline
                        onChange={onHandleChange}
                        error={errors.exp_in_wordError}
                        sx={{
                          "& .MuiSelect-select": {
                            padding: "10px 0",
                            fontSize: 20,
                            textAlign: "left",
                            ":focus": {
                              background: "none",
                            },
                          },
                        }}
                      >
                        <MenuItem value="Select" disabled sx={{ fontSize: 20 }}>
                          <em>Select</em>
                        </MenuItem>
                        {expInWord &&
                          expInWord.length > 0 &&
                          expInWord.map((item, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={item}
                                sx={{
                                  fontSize: 20,
                                  "&.Mui-selected": {
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    ":hover": {
                                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    },
                                  },
                                }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText sx={{ color: "#d32f2f", fontSize: 16 }}>
                        {errors.exp_in_wordError}
                      </FormHelperText>
                    </FormControl>
                    <TextField
                      className="body1-fonts"
                      id="exp_in_number"
                      label="Tenure"
                      variant="standard"
                      InputProps={{
                        "aria-describedby": "",
                        disableUnderline: true,
                        maxLength: 12,
                        style: {
                          fontSize: 20,
                          borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
                          paddingBottom: "10px",
                        },
                      }}
                      InputLabelProps={{
                        style: { fontSize: 20, color: "#666" },
                      }}
                      sx={{ width: { md: "50%", sm: "100%", xs: "100%" } }}
                      name="exp_in_number"
                      value={gtkyFormData.exp_in_number}
                      onChange={onHandleChange}
                      error={errors.exp_in_numberError}
                      placeholder="Please fill this field"
                      helperText={
                        errors.exp_in_numberError
                          ? errors.exp_in_numberError
                          : " "
                      }
                    />
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box
                      sx={{
                        width: { md: "100%%", sm: "100%", xs: "100%" },
                        textAlign: "left",
                        pt: 2,
                        pb: 3,
                      }}
                    >
                      <Button
                        variant="text"
                        component="label"
                        sx={{
                          width: "100%",
                          justifyContent: "left",
                          borderBottom: "2px solid",
                          borderRadius: 0,
                          color: "#000",
                        }}
                        endIcon={<AttachFileIcon />}
                      >
                        <span
                          style={{
                            fontSize: 20,
                            color: "#666",
                            textTransform: "none",
                            width: "92%",
                            fontWeight: 400,
                          }}
                        >
                          {attachment
                            ? attachment.name
                            : "Upload a picture of yourself"}
                        </span>
                        <input
                          ref={fileRef}
                          onChange={(e) => handleselectedFile(e)}
                          type="file"
                          hidden
                          accept="image/*"
                        />
                      </Button>
                      <Typography
                        sx={{
                          fontSize: 16,
                          marginTop: "6px",
                          color: errors.fileError ? "#d32f2f" : "#666",
                        }}
                      >
                        {errors.fileError
                          ? errors.fileError
                          : `(Please keep
                file size under 5 MB)`}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#666",
                        textAlign: "left",
                        fontSize: 20,
                      }}
                    >
                      I describe myself as
                    </Typography>
                    <TextareaAutosize
                      value={gtkyFormData.description}
                      aria-label="minimum height"
                      minRows={3}
                      maxLength={150}
                      style={{
                        width: mobile ? "350px" : "610px",
                        marginTop: "15px",
                        height: "50px",
                        padding: "10px",
                      }}
                      name="description"
                      onChange={(e) => {
                        onHandleChange(e);
                        recalculate(e);
                      }}
                      required
                      error={errors.descriptionError}
                      id="description"
                    />
                    <Box sx={{ display: "flex" }}>
                      <FormHelperText
                        sx={{
                          color: "#d32f2f",
                          fontSize: 16,
                          mb: 1,
                          display: "flex",
                          flexGrow: 1,
                          alignSelf: "flex-start",
                        }}
                      >
                        {errors.descriptionError}
                      </FormHelperText>
                      <FormHelperText>{textAreaCount}/150 chars</FormHelperText>
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#666",
                        textAlign: "left",
                        fontSize: 20,
                      }}
                    >
                      One quirky fact about me
                    </Typography>
                    <TextareaAutosize
                      value={gtkyFormData.fun_facts}
                      aria-label="minimum height"
                      minRows={3}
                      maxLength={150}
                      onChange={(e) => {
                        onHandleChange(e);
                        calculateLength(e);
                      }}
                      style={{
                        width: mobile ? "350px" : "610px",
                        marginTop: "15px",
                        height: "50px",
                        padding: "10px",
                      }}
                      name="fun_facts"
                      required
                      error={errors.fun_factsError}
                      id="fun_facts"
                    />
                    <Box sx={{ display: "flex" }}>
                      <FormHelperText
                        sx={{
                          color: "#d32f2f",
                          fontSize: 16,
                          mb: 1,
                          display: "flex",
                          flexGrow: 1,
                          alignSelf: "flex-start",
                        }}
                      >
                        {errors.fun_factsError}
                      </FormHelperText>
                      <FormHelperText>{funfactCount}/150 chars</FormHelperText>
                    </Box>
                  </Box>
                  {saveInProgress ? (
                    <CircularProgress sx={{ color: "rgb(204, 0, 0)" }} />
                  ) : (
                    ""
                  )}
                  {responseData && (
                    <Snackbar
                      open={openSuccess}
                      sx={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        bottom: "10px !important",
                        left: "0 !important",
                      }}
                      autoHideDuration={4000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="success"
                        sx={{ width: "100%" }}
                      >
                        Thanks for registering.
                      </Alert>
                    </Snackbar>
                  )}
                  {errorResponse && (
                    <Snackbar
                      open={errorResponse}
                      sx={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        bottom: "10px !important",
                        left: "0 !important",
                      }}
                      autoHideDuration={4000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="error"
                        sx={{ width: "100%" }}
                      >
                        Something went wrong please try after some time
                      </Alert>
                    </Snackbar>
                  )}
                  <Box>
                    {" "}
                    <Button
                      variant="outlined"
                      className="button-textm button-size"
                      sx={{
                        mt: 1,
                        borderRadius: 0,
                        borderColor: "black",
                        px: 2,
                        background: "#CC0000",
                        color: "white",
                        width: { md: "30%", sm: "50%", xs: "50%" },
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#CC0000",
                        },
                      }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}
    </>
  );
};

export default AddGTKY;
