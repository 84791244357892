import { Card, CardContent, Grid, Typography } from '@mui/material';
import styles from './Agenda.module.css';

const AgendaDay3 = () => {
  const generalSessions = [
    {
      image: 'https://gfc.target.com/tiiam/tiiam24/brian.png',
      speaker: 'Brian Cornell',
      designation1: 'CEO',
      // designation2: 'Chief Executive Officer',
    },
    {
      image: 'https://gfc.target.com/tiiam/tiiam24/cara.png',
      speaker: 'Cara Sylvester',
      designation1: 'Chief Guest Experience Officer',
      // designation2: 'Chief Guest Experience Officer',
    },
    {
      image: 'https://gfc.target.com/tiiam/tiiam24/rick.png',
      speaker: 'Rick Gomez',
      designation1: 'EVP & Chief Food Essentials & Beauty Officer',
      // designation2: 'Chief Food, Essentials and Beauty Officer',
    },
    {
      image: 'https://gfc.target.com/tiiam/tiiam24/andrea.png',
      speaker: 'Andrea Zimmerman',
      designation1: 'SVP & President - Target in India',
      // designation2: 'Target in India',
    },
  ];
  const RenderSpeaker = ({ session }) => {
    const { image, speaker, designation1, designation2, bio, isOuterSpace = false } = { ...session };

    return (
      <div style={{ textAlign: isOuterSpace ? 'left' : 'center' }}>
        <img src={image} alt='' width={isOuterSpace ? '25%' : '50%'} />
        <div style={{ textAlign: 'center' }}>
          <Typography
            variant='h5'
            sx={{
              fontWeight: '500',
              lineHeight: '1',
              marginBottom: '5px',
              fontSize: '18px',
              textAlign: 'center',
              width: isOuterSpace ? '27%' : '100%',
            }}
          >
            {speaker}
          </Typography>
          <Typography variant='h6' sx={{ fontWeight: '300', lineHeight: '1.2', fontSize: '16px', textAlign: 'center', width: isOuterSpace ? '27%' : '100%' }}>
            {designation1}
          </Typography>
          <Typography variant='h6' sx={{ fontWeight: '300', lineHeight: '1.2', marginBottom: '15px', fontSize: '16px' }}>
            {designation2}
          </Typography>
          <Typography variant='h6' sx={{ fontWeight: '300', lineHeight: '1.2', marginBottom: '15px', fontSize: '16px', textAlign: 'left' }}>
            {bio}
          </Typography>
        </div>
      </div>
    );
  };
  const RenderSesion = ({ time, title, subTitle, designation, isWelcome = false, isWrap = false }) => {
    return (
      <Card elevation={0} sx={{ minWidth: 275, textAlign: 'left', backgroundColor: 'transparent' }}>
        <CardContent>
          <Typography variant='h6' component='div' sx={{ marginBottom: '15px', fontWeight: '400', color: '#CC0000' }}>
            {time}
          </Typography>
          {isWelcome ? (
            <>
              <Typography variant='h6' component='div' sx={{ marginBottom: '15px', fontWeight: '400', color: '#808080' }}>
                {title}
                <hr className={[styles['agenda-day3-title-hr']]} />
              </Typography>
              <Typography variant='h4' component='div' sx={{ fontWeight: '700' }}>
                {subTitle}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant='h4' component='div' sx={{ fontWeight: '700' }}>
                {subTitle}
              </Typography>
              <Typography variant='h6' component='div' sx={{ marginBottom: '15px', marginTop: '20px', fontWeight: '400', color: '#808080' }}>
                {title}
                {!isWrap && <hr className={[styles['agenda-day3-title-hr']]} />}
              </Typography>
            </>
          )}

          <Typography variant='h6' sx={{ fontWeight: '500', lineHeight: '1.2', marginBottom: '15px', fontSize: '16px' }}>
            {designation}
          </Typography>
        </CardContent>
      </Card>
    );
  };
  return (
    <Grid container>
      <Grid item md={12} sx={{ padding: '0 40px' }}>
        <RenderSesion time={'11:30 am - 1:30 pm'} title={'Welcome'} subTitle={'Team member experiences and lunch'} isWelcome={true} />
      </Grid>
      <Grid item md={12} sx={{ padding: '0 40px', backgroundColor: '#FFF8F8' }}>
        <RenderSesion time={'1:30 pm - 3:30 pm '} title={'Leadership presentations'} subTitle={'General session'} />
      </Grid>
      <Grid item md={12} sx={{ padding: '0 40px', backgroundColor: '#FFF8F8' }}>
        <Grid container>
          {generalSessions.map((session, index) => (
            <Grid item md={3} sx={{ marginLeft: index === 0 && '-6%' }}>
              <RenderSpeaker session={session} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        sx={{
          backgroundColor: '#FFF8F8',
          padding: '0 40px 40px 40px',
          backgroundImage: 'url(https://gfc.target.com/tiiam/tiiam24/outersapces_bannerimage.png)',
          backgroundPosition: 'right bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '40%',
        }}
      >
        <RenderSesion title={'Outer Spaces'} />
        <Grid container>
          <Grid md={6}>
            <RenderSpeaker
              session={{
                image: 'https://gfc.target.com/tiiam/tiiam24/rohan.png',
                speaker: 'Rohan M Bopanna',
                designation1: 'International Tennis Champion',
                isOuterSpace: true,
                bio: 'Meet the man who has defied age and injury to ascend to the No.1 ranking in men’s doubles tennis – a tale of sheer grit, dogged determination and a never-say-die attitude.',
              }}
            />
            {/* <RenderSesion time={''} title={'Leadership Performance'} /> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <RenderSesion time={'3:30 - 4:30 pm'} subTitle={'High-tea and wrap up'} isWrap={true} />
      </Grid>
    </Grid>
  );
};
export default AgendaDay3;
