import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiConfig from "../apiConfig";

const { api } = apiConfig;

export const gtkyApi = createApi({
  reducerPath: "gtkyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: api.host,
    prepareHeaders: (headers, { getState }) => {
      const bearer = getState().session.userInfo.accessToken;
      if (bearer) {
        headers.set("authorization", bearer);
      }
      return headers;
    },
  }),
  tagTypes: ["GTKY"],
  endpoints: (builder) => ({
    getgtky: builder.query({
      query: () => ({
        url: `annual_meeting_contents/v1/getgtky`,
        method: "GET",
        params: {
          key: api.key,
        },
      }),
      providesTags: ["GTKY"],
    }),
    postgtky: builder.mutation({
      async queryFn(payload, _queryApi, _extraOptions, fetchWithBQ) {
        const { attachment, ...restPayload } = payload;
        const formData = new FormData();
        Object.keys(restPayload).map((key) => {
          formData.append(key, restPayload[key]);
          return null;
        });
        if (attachment) {
          formData.append("image_url", attachment);
        }
        const response = await fetchWithBQ(
          {
            url: `annual_meeting_contents/v1/registergtky`,
            method: "POST",
            params: {
              key: api.key,
            },
            body: formData,
          },
          _queryApi,
          _extraOptions
        );
        if (response.error) throw response.error;
        return response.data
          ? { data: response.data }
          : { error: response.error };
      },
      invalidatesTags: ["GTKY"],
    }),
  }),
});

export const { useGetgtkyQuery, usePostgtkyMutation } = gtkyApi;
