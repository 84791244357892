import { Typography } from '@mui/material';

const ArExperience = () => {
  return (
    <div style={{ minHeight: '100vh' }}>
      <model-viewer
        id='model-viewer'
        src='https://gfc.target.com/tiiam/AR_KIRAN/model_viewer_tiiam/Mascot.glb'
        poster='bullseyedogposter.png'
        alt='Bullseye dog'
        shadow-intensity='1'
        camera-controls
        style={{ width: '100%', height: '500px' }}
        autoplay
        auto-rotate
        ar
        ar-modes='scene-viewer quick-look webxr fallback'
      >
        <button slot='ar-button' style={{ backgroundColor: 'white', borderRadius: '4px', border: 'none', position: 'absolute', bottom: '16px', right: '16px' }}>
          Activate AR
        </button>
      </model-viewer>
      <Typography variant='h5'>After entering AR mode, Long press the camera shutter button to record a video.</Typography>
    </div>
  );
};

export default ArExperience;
