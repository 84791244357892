import { Grid, Typography, useMediaQuery } from "@mui/material";
import Carousel from "nuka-carousel";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const Stories = ({ memberStories }) => {
  const desktop = useMediaQuery((theme) => theme.breakpoints.up(1701));
  const tab = useMediaQuery((theme) => theme.breakpoints.between(768, 1023));
  const mobile = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const windowsLaptop = useMediaQuery((theme) =>
    theme.breakpoints.between(1024, 1700)
  );
  const userStyles = {
    carouselProps: {
      // animation: desktop ? '' : 'zoom',
      cellSpacing: desktop ? 20 : 0,
      wrapAround: true,
      cellAlign: "center",
      slidesToShow: desktop || windowsLaptop ? 2.8 : 1.2,
      edgeEasing: "easeLinear",
      enableKeyboardControls: true,
      autoplay: false,
      width: "100%",
    },
    sliderContainer: {
      // border: '1px solid red',
      marginBottom: "20px",
      marginTop: tab || mobile ? "0px" : windowsLaptop ? "100px " : "0px",
      "& .slide": {
        margin: "0 10px",
      },
      "& .slider-container": {
        // border: '5px solid blue',
        width: "100%",
        padding: "0 30px !important",
      },
      ".slider-container .slide .MuiGrid-item": {
        opacity: "inherit !important",
      },

      "& .slider-control-bottomcenter": {
        position: "absolute",
        bottom: desktop ? "-10% !important" : "-8% !important",
        "& .active ": {
          transform: "scale(2)",
        },
      },
      "& .slider-control-centerleft, & .slider-control-centerright": {
        marginTop: "-40px !important",
      },
    },
    speakerName: {
      fontFamily: "TargetBold",
    },
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
        sx={userStyles.sliderContainer}
      >
        <Carousel
          {...userStyles.carouselProps}
          renderAnnounceSlideMessage={({ currentSlide, slideCount }) => `
                Slide ${currentSlide + 1} of ${slideCount}
                `}
          defaultControlsConfig={{
            // prevButtonClassName: classNames(userStyles.prevBtn),
            nextButtonText: (
              <NavigateNextIcon
                aria-label="next slide"
                fontSize={
                  desktop
                    ? "large"
                    : tab || mobile || windowsLaptop
                    ? "10px"
                    : "small"
                }
              />
            ),
            prevButtonText: (
              <NavigateBeforeIcon
                aria-label="previous slide"
                fontSize={desktop ? "large" : "10px"}
              />
            ),

            pagingDotsStyle: {
              fill: "#CC0000",
              padding: mobile ? "6px" : "20px",
              // marginTop: desktop ? '-30px' : '-60px',
              // border: '1px solid',
            },
          }}
        >
          {memberStories.map((story, index) => (
            <Grid item md={12} sm={12} sx={{ p: "20px" }}>
              <a
                href={story.session_streaming_url}
                target="_blank"
                rel="noreferrer"
                style={{ marginLeft: "10px" }}
              >
                <img
                  width="100%"
                  src={story.thumbnail}
                  alt={`video of ${story.name}, video opens in new window.`}
                />
              </a>
              <div>
                <Typography sx={{ ml: "10px" }}>
                  <span style={userStyles.speakerName}>{story.name}</span>{" "}
                  <span>| {story.designation}</span>
                  <br />
                  {story.second_name && (
                    <>
                      <span style={userStyles.speakerName}>
                        {story.second_name}
                      </span>{" "}
                      <span>| {story.second_designation}</span>
                    </>
                  )}
                </Typography>
              </div>
              <Typography>{story.session_description}</Typography>
            </Grid>
          ))}
        </Carousel>
      </Grid>
    </>
  );
};

export default Stories;
