import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { PageApi, ConfigApi } from "enterprise-stencil";
import { gtkyApi } from "./api/gtky";
import { crosswordApi } from "./api/crossword";
import { outerSpacesRegisterApi } from "./api/outerSpaces";
import { photoscapesApi } from "./api/photoscapes";
import counterReducer from "./slicer";
import { sessionSlice } from "./api/session";

// console.log(PageApi.reducerPath)

export const store = configureStore({
  reducer: {
    [PageApi.reducerPath]: PageApi.reducer,
    [ConfigApi.reducerPath]: ConfigApi.reducer,
    [gtkyApi.reducerPath]: gtkyApi.reducer,
    [crosswordApi.reducerPath]: crosswordApi.reducer,
    [outerSpacesRegisterApi.reducerPath]: outerSpacesRegisterApi.reducer,
    [photoscapesApi.reducerPath]: photoscapesApi.reducer,
    [sessionSlice.name]: sessionSlice.reducer,
    counter: counterReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      PageApi.middleware,
      ConfigApi.middleware,
      gtkyApi.middleware,
      crosswordApi.middleware,
      outerSpacesRegisterApi.middleware,
      photoscapesApi.middleware,
    ]),
});

setupListeners(store.dispatch);
