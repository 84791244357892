import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import apiConfig from '../apiConfig';

const { api } = apiConfig;

export const photoscapesApi = createApi({
  reducerPath: 'photoscapesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api.host,
    prepareHeaders: (headers, { getState }) => {
      const bearer = getState().session.userInfo.accessToken;
      if (bearer) {
        headers.set('authorization', bearer);
      }
      return headers;
    },
  }),
  tagTypes: ['PhotoscapesApi'],
  endpoints: (builder) => ({
    getPhotoscapes: builder.query({
      query: ({ userInfo }) => ({
        url: `annual_meeting_contents/v1/photoscapes`,
        method: 'GET',
        params: {
          key: api.key,
          email_id: userInfo.email,
        },
      }),
      providesTags: ['PhotoscapesApi'],
    }),
    getAllPhotoscapes: builder.query({
      query: ({ userInfo }) => ({
        url: `annual_meeting_contents/v1/allphotoscapes`,
        method: 'GET',
        params: {
          key: api.key,
        },
      }),
      providesTags: ['PhotoscapesApi'],
    }),
    uploadPhotos: builder.mutation({
      async queryFn(payload, _queryApi, _extraOptions, fetchWithBQ) {
        const { attachment, ...restPayload } = payload;
        const formData = new FormData();

        Object.keys(restPayload).map((key) => {
          formData.append(key, restPayload[key]);
          return null;
        });

        if (attachment && attachment.length > 0) {
          attachment.forEach((file) => {
            formData.append('picture', file);
          });
        }

        const response = await fetchWithBQ(
          {
            url: `annual_meeting_contents/v1/photoscapes`,
            method: 'POST',
            params: {
              key: api.key,
            },
            body: formData,
          },
          _queryApi,
          _extraOptions
        );
        if (response.error) throw response.error;
        return response.data ? { data: response.data } : { error: response.error };
      },
      invalidatesTags: ['PhotoscapesApi'],
    }),
    updatePhotoInfo: builder.mutation({
      query: (payload) => ({
        url: `annual_meeting_contents/v1/photoscapes`,
        method: 'PUT',
        params: {
          key: api.key,
        },
        body: payload,
      }),
      invalidatesTags: ['updatephotoinfo'],
    }),
  }),
});

export const { useGetPhotoscapesQuery, useGetAllPhotoscapesQuery, useUploadPhotosMutation, useUpdatePhotoInfoMutation } = photoscapesApi;
