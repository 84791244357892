import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiConfig from "../apiConfig";

const { api } = apiConfig;

export const crosswordApi = createApi({
  reducerPath: "crosswordApi",
  baseQuery: fetchBaseQuery({
    baseUrl: api.host,
    prepareHeaders: (headers, { getState }) => {
      const bearer = getState().session.userInfo.accessToken;
      if (bearer) {
        headers.set("authorization", bearer);
      }
      return headers;
    },
  }),
  tagTypes: ["Crossword"],
  endpoints: (builder) => ({
    getcrosswordData: builder.query({
      query: ({ userInfo, date }) => ({
        url: `annual_meeting_contents/v1/crossword`,
        method: "GET",
        params: {
          key: api.key,
          email_id: userInfo.email,
          date: date,
        },
      }),
      providesTags: ["Crossword"],
    }),
    postcrosswordData: builder.mutation({
      query: (payload) => {
        return {
          url: `annual_meeting_contents/v1/crossword`,
          method: "POST",
          params: {
            key: api.key,
          },
          body: {
            ...payload,
          },
        };
      },
      invalidatesTags: ["Crossword"],
    }),
    getcrosswordClues: builder.query({
      query: ({ date }) => ({
        url: `annual_meeting_contents/v1/crossword_clues`,
        method: "GET",
        params: {
          key: api.key,
          date: date,
        },
      }),
      providesTags: ["CrosswordClues"],
    }),
  }),
});

export const {
  useGetcrosswordDataQuery,
  usePostcrosswordDataMutation,
  useGetcrosswordCluesQuery,
} = crosswordApi;
