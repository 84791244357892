import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import apiConfig from '../apiConfig';

const { api } = apiConfig;

export const outerSpacesRegisterApi = createApi({
  reducerPath: 'outerSpacesRegisterApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api.host,
    prepareHeaders: (headers, { getState }) => {
      const bearer = getState().session.userInfo.accessToken;
      if (bearer) {
        headers.set('authorization', bearer);
      }
      return headers;
    },
  }),
  tagTypes: ['OuterSpaces'],
  endpoints: (builder) => ({
    getOuterSpaces: builder.query({
      query: () => ({
        url: `annual_meeting_contents/v1/register`,
        method: 'GET',
        params: {
          key: api.key,
        },
      }),
      providesTags: ['OuterSpaces'],
    }),
    registerOuterSpaces: builder.mutation({
      async queryFn(payload, _queryApi, _extraOptions, fetchWithBQ) {
        const response = await fetchWithBQ(
          {
            url: `annual_meeting_contents/v1/register`,
            method: 'POST',
            params: {
              key: api.key,
            },
            body: payload,
          },
          _queryApi,
          _extraOptions
        );
        if (response.error) throw response.error;
        return response.data ? { data: response.data } : { error: response.error };
      },
      invalidatesTags: ['OuterSpaces'],
    }),

    getSeatingCapacity: builder.query({
      query: () => ({
        url: `annual_meeting_contents/v1/seats`,
        method: 'GET',
        params: {
          key: api.key,
        },
      }),
      providesTags: ['Seats'],
    }),
    giftReg: builder.mutation({
      query: (payload) => ({
        url: `annual_meeting_contents/v1/gifts/approve`,
        method: 'POST',
        params: {
          key: api.key,
        },
        body: payload,
      }),
      invalidatesTags: ['Gifts'],
    }),
    admitUser: builder.mutation({
      query: (payload) => ({
        url: `annual_meeting_contents/v1/outerspaceadmission`,
        method: 'POST',
        params: {
          key: api.key,
        },
        body: payload,
      }),
      invalidatesTags: ['admitUser'],
    }),
    getTotalAdmissions: builder.query({
      query: ({ sessionName }) => ({
        url: `annual_meeting_contents/v1/outerspaceadmission`,
        method: 'GET',
        params: {
          key: api.key,
          sessionName: sessionName,
        },
      }),
      providesTags: ['Seats'],
    }),
    getTotalGiftsGiven: builder.query({
      query: () => ({
        url: `annual_meeting_contents/v1/gifts`,
        method: 'GET',
        params: {
          key: api.key,
        },
      }),
      providesTags: ['Gifts'],
    }),
  }),
});

export const {
  useGetOuterSpacesQuery,
  useRegisterOuterSpacesMutation,
  useGetSeatingCapacityQuery,
  useGiftRegMutation,
  useAdmitUserMutation,
  useGetTotalAdmissionsQuery,
  useGetTotalGiftsGivenQuery,
  useRegister2024EventsMutation,
} = outerSpacesRegisterApi;
