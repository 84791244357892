import { useSelector } from 'react-redux';
import { useGetAllPhotoscapesQuery, useUpdatePhotoInfoMutation } from '../../api/photoscapes';
import { useEffect, useState } from 'react';
import { Button, Paper, TablePagination, Avatar, TextField, FormControlLabel, Switch, Typography, Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Base64 } from 'js-base64';

const PhotoDashboard = ({ status: tabStatus }) => {
  const userInfo = useSelector((state) => state.session.userInfo);
  const [allPhotosData, setAllPhotosData] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data: photoscapesData, refetch: refetchAllPhotos } = useGetAllPhotoscapesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      skip: !userInfo.lanId,
    }
  );
  const [updatePhotoInfo, { data: updatedInfo }] = useUpdatePhotoInfoMutation();

  useEffect(() => {
    if (photoscapesData) {
      const allPhotoScapeEntries = [...photoscapesData];
      allPhotoScapeEntries.pop();
      setAllPhotosData(allPhotoScapeEntries);
    }
  }, [photoscapesData]);

  useEffect(() => {
    if (updatedInfo) {
      refetchAllPhotos();
    }
  }, [refetchAllPhotos, updatedInfo]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const descriptionChange = (e, row, pIndex, rIndex) => {
    var updatedData;
    // console.log('E ', e.target.value);
    // console.log(row, pIndex, rIndex);
    updatedData = { ...row };
    if (e.target.value !== '') {
      let tempPhotos = [...row.photos];

      const tempPhotoObj = { ...tempPhotos[pIndex] };
      tempPhotoObj['descritption'] = e.target.value;
      tempPhotos[pIndex] = { ...tempPhotoObj };
      updatedData = {
        ...row,
        photos: tempPhotos,
        // descriptionError: '',
      };
    } else {
      updatedData = {
        ...row,
        descriptionError: 'Description cannot be empty',
      };
    }
    // console.log('tabStatus ', tabStatus);
    let updatedResults = [...allPhotosData];
    // if (tabStatus[0] === 'approved') {
    //   console.log('inside approved');
    //   updatedResults = updatedResults.filter((res) => res.status === 'approved');
    // }
    if (tabStatus[0] === 'pending') {
      // console.log('inside pending');
      updatedResults = updatedResults.filter((res) => res.status === 'pending');
    } else {
      updatedResults = updatedResults.filter((res) => res.status === 'approved' || res.status === 'published');
      // console.log('updatedResults in else ', updatedResults);
    }
    // if (tabStatus[1] === 'published') {
    //   updatedResults = updatedResults.filter((res) => res.status === 'published');
    // }
    // console.log('updatedResults ', rIndex, updatedResults);
    // console.log(' updatedResults[rIndex]  ', rIndex, updatedResults[rIndex]);
    updatedResults[rIndex] = updatedData;
    // console.log(' updatedResults[rIndex] after ', rIndex, updatedResults[rIndex]);
    setAllPhotosData(updatedResults);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleApprove = () => {
    const tempData = { ...selectedRow };
    tempData['status'] = 'approved';

    updatePhotoInfo(tempData);

    // saveGTKYFormData({ ...tempData });
  };
  const handleClickOpen = (e, row) => {
    // if (isValidForm(row)) {
    setSelectedRow(row);
    // setApprovedData("");
    setOpen(true);
    // }
  };
  const handlePublish = (isChecked, row) => {
    const tempData = { ...row };
    if (isChecked) {
      tempData['status'] = 'published';
    } else {
      tempData['status'] = 'approved';
    }

    updatePhotoInfo(tempData);
  };
  function renderPhotoCells(row, rIndex) {
    const rowToRender = { ...row };
    if (rowToRender.photos.length === 1) {
      const temp = {};
      temp['photoUrl'] = '';
      temp['descritption'] = 'NOT AVAILABLE';
      rowToRender['photos'] = [...rowToRender.photos, temp];
    }
    return rowToRender.photos.map((photoObj, pIndex) => (
      <>
        {pIndex < 2 && (
          <>
            <TableCell>
              <Avatar
                variant='rounded'
                alt={photoObj.photoUrl === '' ? 'NA' : row.name}
                src={photoObj.compressedPhotoUrl ? photoObj.compressedPhotoUrl : photoObj.photoUrl}
                imgProps={{ loading: 'lazy' }}
                sx={{
                  width: 250,
                  height: 250,
                  mb: 1,
                }}
              >
                {photoObj.photoUrl === '' && 'NA'}
              </Avatar>
            </TableCell>
            <TableCell>
              <TextField
                aria-label='description'
                multiline={true}
                minRows={3}
                inputProps={{ maxLength: 140 }}
                value={photoObj.descritption}
                onChange={(e) => descriptionChange(e, row, pIndex, rIndex)}
                style={{ width: 250 }}
                disabled={photoObj.photoUrl === '' || rowToRender.status === 'published'}
              />
              <span
                style={{
                  display: 'inherit',
                  color: '#d32f2f',
                }}
              >
                {row.descriptionError ? row.descriptionError : ''}
              </span>
            </TableCell>
          </>
        )}
      </>
    ));
  }

  let totalPhotos = 0;
  let totalPending = 0;
  let totalApproved = 0;
  let totalPublihed = 0;

  allPhotosData &&
    allPhotosData.forEach((photoObj) => {
      totalPhotos = totalPhotos + photoObj.photos.length;
      if (photoObj.status === 'pending') {
        totalPending++;
      }
      if (photoObj.status === 'approved') {
        totalApproved++;
      }
      if (photoObj.status === 'published') {
        totalPublihed++;
      }
    });
  let adminPanelUsers = [
    'QTYzMzU4NQ==',
    'WjAwQ1ZRSw==',
    'WjAxM0pMSw==',
    'WjA4Njk5OQ==',
    'WjAwQ1cyVw==',
    'WjAwRFBXMQ==',
    'WjAwQ1ZONA==',
    'WjAwQkNHQw==',
    'WjA4NTM3Mw==',
  ];
  adminPanelUsers = adminPanelUsers.map((id) => Base64.decode(id));
  return (
    <>
      {!adminPanelUsers.includes(userInfo.lanId) ? (
        <Typography variant='h6' sx={{ color: '#CC0000', fontSize: '40px' }}>
          Access Denied!!
        </Typography>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant='h6'>Total Submissions : {allPhotosData && allPhotosData.length}</Typography>
            </Grid>
            <Grid item>
              <Typography variant='h6'>Total Photos : {totalPhotos}</Typography>
            </Grid>
            <Grid item>
              <Typography variant='h6'>Pending : {totalPending}</Typography>
            </Grid>
            <Grid item>
              <Typography variant='h6'>Approved : {totalApproved}</Typography>
            </Grid>
            <Grid item>
              <Typography variant='h6'>Published : {totalPublihed}</Typography>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, margin: '20px 0' }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Pyramid</TableCell>
                  <TableCell>Picture 1</TableCell>
                  <TableCell style={{ width: '20%' }}>Description 1</TableCell>
                  <TableCell>Picture 2</TableCell>
                  <TableCell style={{ width: '20%' }}>Description 2</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allPhotosData &&
                  allPhotosData
                    .filter((photo) => tabStatus.includes(photo.status))
                    .slice()
                    .map((row, rIndex) => (
                      <TableRow key={rIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component='th' scope='row'>
                          {row.name}
                        </TableCell>
                        <TableCell>{row.pyramid}</TableCell>
                        {renderPhotoCells(row, rIndex)}
                        <TableCell>
                          {tabStatus.includes('approved') ? (
                            <FormControlLabel
                              control={<Switch checked={row.status === 'published' ? true : false} onChange={(e) => handlePublish(e.target.checked, row)} />}
                              label='Publish'
                            />
                          ) : tabStatus.includes('pending') ? (
                            <Button
                              variant='contained'
                              color='warning'
                              size='small'
                              // disabled={row.fileError === undefined}
                              onClick={(e) => handleClickOpen(e, row)}
                              sx={{
                                backgroundColor: '#CC0000',
                                borderRadius: '20px',
                                textTransform: 'lowercase',
                                '&:hover': {
                                  backgroundColor: '#CC0000',
                                },
                              }}
                            >
                              Approve
                            </Button>
                          ) : (
                            <>Published</>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component='div'
            count={allPhotosData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-approve' aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-approve'>{'Approve Changes'}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>Are you sure you want to approve the changes</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={() => {
                  handleClose();
                  handleApprove();
                }}
                autoFocus
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default PhotoDashboard;
