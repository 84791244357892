import React, { useState, useEffect } from 'react';
import { Button, Paper, TextareaAutosize, TablePagination, Avatar, CircularProgress, Badge } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useGetgtkyQuery } from '../../api/gtky';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { usePostgtkyMutation } from '../../api/gtky';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { saveAs } from 'file-saver';

const Review = () => {
  const userInfo = useSelector((state) => state.session.userInfo);
  const [open, setOpen] = React.useState(false);
  const [gtkyResults, setgtkyResults] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [approvedData, setApprovedData] = React.useState("");
  // const [attachment, setAttachment] = useState();
  const { data: gtkyData } = useGetgtkyQuery(
    {},

    {
      refetchOnMountOrArgChange: true,
      skip: !userInfo.lanId,
    }
  );

  const [saveGTKYFormData, { data: responseData, isLoading: approveInProgress, isError: error }] = usePostgtkyMutation();

  useEffect(() => {
    if (gtkyData) {
      setgtkyResults(gtkyData);
    }
  }, [gtkyData, responseData]);

  useEffect(() => {
    // setShow(true);
    if (responseData) {
      // setApprovedData(responseData);
    }
    if (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData, error]);

  const descriptionChange = (e, row, index) => {
    var updatedData;
    updatedData = { ...row };
    if (e.target.value !== '') {
      updatedData = {
        ...row,
        description: e.target.value,
        descriptionError: '',
      };
    } else {
      updatedData = {
        ...row,
        descriptionError: 'Description cannot be empty',
      };
    }
    const updatedResults = [...gtkyResults.slice(0, index), updatedData, ...gtkyResults.slice(index + 1)];
    setgtkyResults(updatedResults);
  };

  const funFactsChange = (e, row, index) => {
    var updatedData;
    updatedData = { ...row };
    if (e.target.value !== '') {
      updatedData = {
        ...row,
        fun_facts: e.target.value,
        funFactsError: '',
      };
    } else {
      updatedData = {
        ...row,
        funFactsError: 'Funfacts cannot be empty',
      };
    }
    const updatedResults = [...gtkyResults.slice(0, index), updatedData, ...gtkyResults.slice(index + 1)];
    setgtkyResults(updatedResults);
  };

  const handleselectedFile = (event, row, index) => {
    var updatedData;
    if (event.target.files[0] && event.target.files[0].size < 100000) {
      updatedData = {
        ...row,
        image_url: event.target.files[0],
        fileError: '',
      };
    } else {
      updatedData = { ...row, fileError: 'File size is more than 100KB' };
    }
    const updatedResults = [...gtkyResults.slice(0, index), updatedData, ...gtkyResults.slice(index + 1)];
    setgtkyResults(updatedResults);
  };

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  const downloadImage = (event, row) => {
    saveAs(row.image_url, `${row.name}`);
  };

  const handleClickOpen = (e, row) => {
    if (isValidForm(row)) {
      setSelectedRow(row);
      // setApprovedData("");
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isValidForm = (row) => {
    let errorVal = {
      fileError: row.fileError ? row.fileError : '',
      descriptionError: row.descriptionError ? row.descriptionError : '',
      funFactsError: row.funFactsError ? row.funFactsError : '',
    };
    const isError = Object.values(errorVal).some((k) => k !== '');
    return !isError;
  };
  const handleApprove = () => {
    const tempData = { ...selectedRow };
    tempData['is_approved'] = true;

    delete tempData['fileError'];
    delete tempData['descriptionError'];
    delete tempData['funFactsError'];

    saveGTKYFormData({ ...tempData });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, margin: '20px', border: '1px solid grey' }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {/* <TableCell>Email</TableCell> */}
              <TableCell>Pyramid</TableCell>
              <TableCell>Tenure</TableCell>
              <TableCell style={{ width: '20%' }}>Description</TableCell>
              <TableCell style={{ width: '20%' }}>Fun Facts</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gtkyResults.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component='th' scope='row'>
                  {row.name}
                </TableCell>
                {/* <TableCell>{row.email}</TableCell> */}
                <TableCell>{row.pyramid}</TableCell>
                <TableCell>
                  {row.exp_in_number}
                  {row.exp_in_word}
                </TableCell>
                <TableCell>
                  <TextareaAutosize
                    aria-label='description'
                    minRows={4}
                    maxLength={150}
                    value={row.description}
                    onChange={(e) => descriptionChange(e, row, index)}
                    style={{ width: 350 }}
                  />
                  <span
                    style={{
                      display: 'inherit',
                      color: '#d32f2f',
                    }}
                  >
                    {row.descriptionError ? row.descriptionError : ''}
                  </span>
                </TableCell>
                <TableCell>
                  <TextareaAutosize
                    aria-label='funfacts'
                    minRows={4}
                    maxLength={150}
                    value={row.fun_facts}
                    onChange={(e) => funFactsChange(e, row, index)}
                    style={{ width: 350 }}
                  />

                  <span
                    style={{
                      display: 'inherit',
                      color: '#d32f2f',
                    }}
                  >
                    {row.funFactsError ? row.funFactsError : ''}
                  </span>
                </TableCell>
                <TableCell>
                  <Badge
                    overlap='circular'
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      <SmallAvatar alt={`download${index}`} sx={{ cursor: 'pointer' }} onClick={(e) => downloadImage(e, row)}>
                        <FileDownloadOutlinedIcon></FileDownloadOutlinedIcon>
                      </SmallAvatar>
                    }
                  >
                    <Avatar
                      alt={row.name}
                      src={row.image_url}
                      sx={{
                        width: 70,
                        height: 70,
                        mb: 1,
                      }}
                    ></Avatar>
                  </Badge>

                  <Button
                    variant='contained'
                    component='label'
                    sx={{
                      height: '30px',
                      width: '150px',
                      ml: 2,
                      backgroundColor: 'grey',
                      ':hover': {
                        backgroundColor: 'grey',
                      },
                    }}
                  >
                    Upload Image
                    <input type='file' hidden accept='image/*' onChange={(e) => handleselectedFile(e, row, index)} />
                  </Button>
                  <span>{row?.image_url ? row?.image_url.name : ''}</span>
                  <span
                    style={{
                      display: 'inherit',
                      color: row.fileError ? '#d32f2f' : '#000',
                    }}
                  >
                    {row.fileError
                      ? row.fileError
                      : `(Please keep
              file size under 100 KB)`}
                  </span>
                </TableCell>
                <TableCell>
                  {' '}
                  <Button
                    variant='outlined'
                    color='warning'
                    size='small'
                    // disabled={row.fileError === undefined}
                    onClick={(e) => handleClickOpen(e, row)}
                    sx={{
                      color: '#32CD32',
                      border: '1px solid #32CD32',
                      ':hover': {
                        border: '1px solid #32CD32',
                      },
                    }}
                  >
                    {row.is_approved ? 'Re-edit and Approve' : 'Save and Approve'}
                  </Button>
                  {approveInProgress && selectedRow.name === row.name ? (
                    <CircularProgress sx={{ color: 'rgb(204, 0, 0)', ml: 2, mt: 1 }}></CircularProgress>
                  ) : (
                    ''
                  )}
                  {/* {!approveInProgress && approvedData.name === row.name ? (
                    <span style={{ display: "inherit", paddingTop: "20px" }}>
                      Approved Successfully
                    </span>
                  ) : (
                    ""
                  )} */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component='div'
        count={gtkyResults.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-approve' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-approve'>{'Approve Changes'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>Are you sure you want to approve the changes</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleClose();
              handleApprove();
            }}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Review;
