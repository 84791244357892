export const pyramid = [
  "Marketing",
  "Target Tech",
  "Data Sciences",
  "Digital Ops & Product",
  "Enterprise Capacity Planning",
  "Global Supply Chain",
  "Food & Beverages",
  "Merchandising, Capabilities & Strategy",
  "Target Properties, CRE & Procurement",
  "Finance",
  "Legal Affairs",
  "Communications",
  "Process, Innovation & Automation",
  "Human Resources",
];
export const expInWord = ["Days", "Month", "Months", "Year", "Years"];
