// import Crossword from "@jaredreisinger/react-crossword";
import { useEffect, useRef, useState } from 'react';
import React, { createRef } from 'react';
import { useScreenshot } from 'use-react-screenshot';
import { useSelector } from 'react-redux';
import { useGetcrosswordDataQuery, usePostcrosswordDataMutation } from '../../api/crossword';
import { targetTriviaDayOne, targetTriviaDayTwo, targetTriviaDayThree } from './CrosswordQues';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, CircularProgress, Box, Tab, Tabs, Typography, TextField, InputLabel } from '@mui/material';
import Aprl24 from '../../assets/images/Aprl24.png';
import Aprl25 from '../../assets/images/Aprl25.png';
import Aprl26 from '../../assets/images/Aprl26.png';
import accessbltyClues from './accessibilityClues.json';
import { useLocation } from 'react-router-dom';

const CrossWord = () => {
  const location = useLocation();
  const userInfo = useSelector((state) => state.session.userInfo);
  const [showAnswers, setShowAnswers] = useState(false);
  const [openPreviousDay, setOpenPreviousDay] = useState(false);
  const [previousDayAnswer, setPreviousDayAnswer] = useState([]);
  const [value, setValue] = React.useState(0);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorResponse, setErrorResponse] = useState(false);
  const tabLabels = ['April 24', 'April 25', 'April 26'];
  const [date, setDate] = useState('');
  const startDate = 24;
  const endDate = 26;
  const todayDate = new Date().getDate();
  const tabPanelData = [targetTriviaDayOne, targetTriviaDayTwo, targetTriviaDayThree];
  const [accessibilityAnswer, setAccessibilityAnswer] = useState(false);

  const crosswordImages = [Aprl24, Aprl25, Aprl26];

  const { data: getcrossWordAnswer, isFetching: answerLoading } = useGetcrosswordDataQuery(
    { userInfo, date },
    {
      // refetchOnMountOrArgChange: true,
      skip: !(userInfo.lanId && showAnswers),
    }
  );
  const [postCrosswordAnswer, { data: crosswordAnswer, isLoading: saveInProgress, isError: responseError }] = usePostcrosswordDataMutation();

  // console.log(crosswordClues);
  // useEffect(() => {
  //   console.log(showAnswers);
  // }, [showAnswers]);

  const apr25Tab = () => {
    if (location.pathname.slice(1) === 'things-to-do') {
      window.scrollTo(0, 500);
    } else {
      window.scrollTo(0, 0);
    }
    setValue(1);
    setDate(startDate + 1);
    setOpenSuccess(false);
    setErrorResponse(false);
    setShowAnswers(false);
    setAccessibilityAnswer(false);
  };

  const aprl24Tab = () => {
    if (location.pathname.slice(1) === 'things-to-do') {
      window.scrollTo(0, 500);
    } else {
      window.scrollTo(0, 0);
    }
    setValue(0);
    setDate(startDate);
    setOpenSuccess(false);
    setErrorResponse(false);
    setShowAnswers(false);
    setAccessibilityAnswer(false);
  };
  const crossWordRef = useRef(null);
  const ref = createRef(null);
  // eslint-disable-next-line no-unused-vars
  const [image, takeScreenshot] = useScreenshot();
  const getImage = () => {
    if (location.pathname.slice(1) === 'things-to-do') {
      window.scrollTo(0, 500);
    } else {
      window.scrollTo(0, 0);
    }
    takeScreenshot(ref.current).then((res) => {
      const payload = {
        name: userInfo.fullName,
        email: userInfo.email,
        date: todayDate,
        answer_image: res,
      };
      postCrosswordAnswer(payload);
    });
    // crossWordRef?.current?.reset();
  };

  useEffect(() => {
    if (getcrossWordAnswer) {
      setPreviousDayAnswer(getcrossWordAnswer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getcrossWordAnswer, answerLoading]);

  useEffect(() => {
    if (crosswordAnswer) {
      // setOpenSuccess(true);
    }
    if (responseError) {
      setErrorResponse(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crosswordAnswer, responseError, saveInProgress]);

  useEffect(() => {
    setValue(todayDate - startDate < 3 ? todayDate - startDate : 2);
    setDate(todayDate <= endDate ? todayDate : endDate);
    setAccessibilityAnswer(false);
    setTimeout(() => {
      if (crossWordRef.current) {
        crossWordRef?.current?.reset();
      }
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCrosswordData = () => {
    if (location.pathname.slice(1) === 'things-to-do') {
      window.scrollTo(0, 500);
    } else {
      window.scrollTo(0, 0);
    }
    setShowAnswers(true);
    setOpenPreviousDay(true);
    console.log(openSuccess);
  };

  const handleClose = () => {
    setOpenPreviousDay(false);
    setShowAnswers(false);
    // setPreviousDayAnswer([]);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setDate(newValue + startDate);
    setOpenSuccess(false);
    setErrorResponse(false);
    setShowAnswers(false);
    setAccessibilityAnswer(false);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <>
      <div style={{ textAlign: 'center', marginTop: '4%' }}>
        <Box
          sx={{
            backgroundColor: '#CC0000',
            backgroundRepeat: 'no-repeat',
            paddingLeft: '10%',
            textAlign: 'left',
          }}
        >
          <Typography variant='h3' component='h2' className='h3-fonts' sx={{ pt: 4, color: '#fff' }}>
            The TIIAM 2023 Crossword Puzzles
          </Typography>
          <Typography variant='h4' component='h3' className='h4-fonts' sx={{ mt: 2, color: '#fff' }}>
            Test your knowledge about Target’s owned brands and trivia about Target with a daily crossword.
          </Typography>
          <Typography variant='body1' sx={{ mb: 4, pb: 8, color: '#fff' }}>
            Read the clues on the right and click within the numbered boxes in the grid to begin typing your answers.
          </Typography>
        </Box>
        <div>
          <div>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                centered
                value={value}
                onChange={handleChange}
                // aria-label="basic tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: '#CC0000',
                    height: 4,

                    // borderBottom: "6px solid black",
                  },
                }}
              >
                {tabLabels.length > 0 &&
                  tabLabels.map((item, index) => {
                    return (
                      <Tab
                        label={item}
                        key={index}
                        style={{ minWidth: '13%' }}
                        sx={{
                          color: 'black !important',
                          '&.Mui-disabled': {
                            color: 'grey !important',
                          },
                        }}
                        disabled={parseInt(item.split(' ')?.[1]) > todayDate}
                        {...a11yProps(index)}
                      />
                    );
                  })}
              </Tabs>
            </Box>
            {answerLoading || saveInProgress ? <CircularProgress sx={{ color: 'rgb(204, 0, 0)' }}></CircularProgress> : ''}
            {/* {saveInProgress ? (
                  <CircularProgress sx={{ color: "rgb(204, 0, 0)" }} />
                ) : (
                  ""
                )} */}
            {crosswordAnswer && !saveInProgress && (
              <Box className='body1-fontm' sx={{ m: 5 }}>
                {todayDate <= 25 ? (
                  <Typography variant='body1'>
                    Thanks for your submission! Come back tomorrow to see if you got the crossword right, and a new crossword to try out.
                  </Typography>
                ) : (
                  <Typography variant='body1'>Thanks for your submission! Come back tomorrow to see if you got the crossword right.</Typography>
                )}
                <Typography variant='body1'>
                  Check out the Brand Showcase across our offices this week to discover more about what the Target’s Owned Brand Sourcing & Development team
                  sources from India.{' '}
                </Typography>
              </Box>
            )}
            {errorResponse && (
              <Box className='body1-fontm'>
                <Typography variant='body1'>Something went wrong please try after some time</Typography>
              </Box>
            )}
            {/* {openPreviousDay &&
            !answerLoading &&
            previousDayAnswer?.length === 0 &&
            showAnswers ? (
              <Typography variant="body1" sx={{ m: 5 }}>
                Looks like you have not played!
              </Typography>
            ) : (
              ""
            )} */}
            {tabPanelData.length > 0 &&
              tabPanelData.map((item, index) => (
                <TabPanel value={value} index={index} aria-hidden='true'>
                  <div ref={ref}>
                    <div
                      style={{
                        display: 'flex',
                        width: '90%',
                        margin: '0 10%',
                      }}
                    >
                      {date === todayDate ? (
                        <></>
                      ) : (
                        // <Crossword
                        //   data={item}
                        //   ref={crossWordRef}
                        //   theme={{
                        //     textColor: "#CC0000",
                        //     numberColor: "#CC0000",
                        //   }}
                        // />
                        <img src={crosswordImages[index]} alt='correct answer' style={{ width: '90%' }} />
                      )}
                    </div>
                  </div>
                </TabPanel>
              ))}
          </div>
        </div>
        <div
          aria-hidden='true'
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '57%',
            gap: 10,
            margin: '0% 11%',
          }}
        >
          {date === todayDate && (
            <Button
              variant='outlined'
              className='button-textm button-size'
              sx={{
                my: 4,
                px: 5,
                //   width: { md: "25%", xs: "90%", sm: "40%" },
                borderRadius: 0,
                borderColor: 'black',
                color: 'black',
                textTransform: 'none',
                '&:hover': {
                  border: '1px solid #000',
                  backgroundColor: 'transparent',
                },
              }}
              onClick={getImage}
            >
              Save my answers
            </Button>
          )}
          {date < todayDate ? (
            <>
              <Button
                variant='outlined'
                className='button-textm button-size'
                sx={{
                  my: 4,
                  px: 5,
                  //   width: { md: "25%", xs: "90%", sm: "40%" },
                  borderRadius: 0,
                  borderColor: 'black',
                  color: 'black',
                  textTransform: 'none',
                  '&:hover': {
                    border: '1px solid #000',
                    backgroundColor: 'transparent',
                  },
                }}
                onClick={getCrosswordData}
              >
                Show my answers
              </Button>
              {/* <Button
                  variant="outlined"
                  className="button-textm button-size"
                  sx={{
                    my: 4,
                    px: 5,
                    //   width: { md: "25%", xs: "90%", sm: "40%" },
                    borderRadius: 0,
                    borderColor: "black",
                    color: "black",
                    textTransform: "none",
                    "&:hover": {
                      border: "1px solid #000",
                      backgroundColor: "transparent",
                    },
                  }}
                  onClick={fillAllAnswers}
                >
                  Show correct answer
                </Button> */}
            </>
          ) : (
            ''
          )}
          {value !== 0 && todayDate > 24 && (
            <>
              <Button
                variant='outlined'
                className='button-textm button-size'
                sx={{
                  my: 4,
                  px: 5,
                  //   width: { md: "25%", xs: "90%", sm: "40%" },
                  borderRadius: 0,
                  borderColor: 'black',
                  color: 'black',
                  textTransform: 'none',
                  '&:hover': {
                    border: '1px solid #000',
                    backgroundColor: 'transparent',
                  },
                }}
                onClick={aprl24Tab}
              >
                Apr 24 solution
              </Button>
            </>
          )}
          {value !== 0 && todayDate > 25 && date !== 25 && (
            <>
              <Button
                variant='outlined'
                className='button-textm button-size'
                sx={{
                  my: 4,
                  px: 5,
                  //   width: { md: "25%", xs: "90%", sm: "40%" },
                  borderRadius: 0,
                  borderColor: 'black',
                  color: 'black',
                  textTransform: 'none',
                  '&:hover': {
                    border: '1px solid #000',
                    backgroundColor: 'transparent',
                  },
                }}
                onClick={apr25Tab}
              >
                Apr 25 solution
              </Button>
            </>
          )}
        </div>
        <Box
          sx={{
            clip: 'rect(0 0 0 0)',
            clipPath: 'inset(50%)',
            height: '1px',
            overflow: 'hidden',
            position: 'absolute',
            whiteSpace: 'nowrap',
            width: '1px',
            margin: '0 10%',
          }}
        >
          <Typography className='body1'>Please fill in the information below and submit your answers!</Typography>
          {accessbltyClues[date]?.map((item, index) => (
            <Box sx={{ display: 'flex', gap: '20px', my: 3 }}>
              <InputLabel
                for={index}
                sx={{
                  fontSize: 20,
                  color: '#666',
                  '&.Mui-focused': {
                    color: '#666',
                  },
                }}
              >
                {item.question}
              </InputLabel>
              {accessibilityAnswer || date < todayDate ? <Typography variant='body1'>{item.answer}</Typography> : <TextField id={index} variant='outlined' />}
            </Box>
          ))}
          <Button
            variant='outlined'
            className='button-textm button-size'
            sx={{
              my: 4,
              px: 5,
              //   width: { md: "25%", xs: "90%", sm: "40%" },
              borderRadius: 0,
              borderColor: 'black',
              color: 'black',
              textTransform: 'none',
              '&:hover': {
                border: '1px solid #000',
                backgroundColor: 'transparent',
              },
            }}
            onClick={() => {
              setAccessibilityAnswer(true);
            }}
          >
            Submit
          </Button>
        </Box>
        <Dialog
          open={openPreviousDay && !answerLoading && previousDayAnswer && previousDayAnswer.length > 0}
          onClose={() => handleClose()}
          aria-labelledby='alert-dialog-approve'
          aria-describedby='alert-dialog-description'
          // sx={{ maxWidth: "100% !important" }}
          sx={{
            '& .MuiDialog-paper': { maxWidth: '100% !important' },
          }}
        >
          <DialogTitle id='alert-dialog-approve'>{'Previous Day answers'}</DialogTitle>
          {previousDayAnswer && previousDayAnswer.length > 0 && !answerLoading && (
            <DialogContent>
              <img src={previousDayAnswer[0]?.answer_image} alt='previous day answer screenshot' width='100%' />
            </DialogContent>
          )}

          <DialogActions>
            <Button onClick={() => handleClose()} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default CrossWord;
