import React, { useEffect, useState } from 'react';
import './App.css';
import { useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { indigo, pink } from '@mui/material/colors';
import utilFunction from './Components/Shared/utilFunction';
import GlobalStyles from '@mui/material/GlobalStyles';
import { AuthProvider, useAuth } from '@praxis/component-auth';
import { BrowserRouter } from 'react-router-dom';
import apiConfig from './apiConfig';
import { Route, Routes } from 'react-router-dom';
import { ProtectedElement } from '@praxis/component-auth';
import { Box, CircularProgress, IconButton, Stack } from '@mui/material';
import { Helmet } from 'react-helmet';

import { PageLoader, StencilTenant, useTenantContext, useGetPageContentQuery } from 'enterprise-stencil';
import { store } from './store';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { setUserSession } from './api/session';
import ScrollToTop from './Components/Shared/ScrollToTop';
import ArrowUpwardTwoToneIcon from '@mui/icons-material/ArrowUpwardTwoTone';
// import WindowSwap from "./Components/WindowSwap";
import Header from './Components/Layout/Header';
import Footer from './Components/Layout/Footer';
import LiveCounter from './Components/LiveCounter';
import Gallery from './Components/Gallery/Gallery';
import Review from './Components/Review/Review';
import AddGTKY from './Components/AddGTKY/AddGTKY';
import Faqs from './Components/Faqs/Faqs';
import CrossWord from './Components/Crossword/Crossword';
import Register from './Components/Register/Register';
// import Scanner from './Components/Scanner/Scanner';
import OuterspacesAdmission from './Components/Scanner/OuterspaceAdmission';
import RegisterForEvent from './Components/Register/RegisterForEvent';
import ReportGenerator from './Components/Register/ReportGenerator';
import UploadPhoto from './Components/UploadPhoto/UploadPhoto';
import PhotoTabs from './Components/UploadPhoto/PhotoTabs';
import PhotoscapesGallery from './Components/PhotoscapesGallery/PhotoscapesGallery';
import WhatsInStore from './Components/WhatsInStore/WhatsInStore';
import ArExperience from './Components/AR/ArExperience';
import VideoCarousel from './Components/VideoCarousel/VideoCarousel';

const customComponents = {
  liveCounter: LiveCounter,
  gallery: Gallery,
  addGTKY: AddGTKY,
  faqs: Faqs,
  outerspace: Register,
  photoscapes2024: PhotoscapesGallery,
  tiiamagenda: WhatsInStore,
  videocarousel: VideoCarousel,
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ScrollToTopButtonOnPage = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: '-200px 0' }}>
      <IconButton onClick={scrollToTop}>
        <ArrowUpwardTwoToneIcon sx={{ display: visible ? 'inline' : 'none' }} />
      </IconButton>
    </Box>
  );
};

const Container = (props) => {
  const location = useLocation();
  const { tenantId } = useTenantContext();
  const [pageData, setPageData] = React.useState(null);
  var analyticsFlag = location.search.includes('?preview=');
  const userInfo = useSelector((state) => state.session.userInfo);
  let query = useQuery();
  const preview = query.get('preview');
  const { data: fetchedData, isLoading } = useGetPageContentQuery(
    {
      tenantId,
      slug: location.pathname.slice(1) ? `/${location.pathname.slice(1)}` : '',
      previewToken: preview,
    },
    { skip: !tenantId }
  );

  // pop up variables
  const [openPopUp, setOpenPopUp] = useState(false);
  const [openUploadPopUp, setOpenUploadPopUp] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenPopUp(false);
    setOpenUploadPopUp(false);
  };
  // end of pop up variables

  React.useEffect(() => {
    setPageData(fetchedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedData]);

  React.useEffect(() => {
    setTimeout(() => {
      const courtcardsBtns = document.querySelectorAll('.courtcards button');

      courtcardsBtns.forEach((el) => {
        if (el.textContent === 'registerregister') {
          el.addEventListener('click', function () {
            setOpenPopUp(true);
          });
        }
        if (el.textContent === 'uploadupload') {
          el.addEventListener('click', function () {
            setOpenUploadPopUp(true);
          });
        }
      });
    }, 2500);

    setTimeout(() => {
      const allRegBtns = document.querySelectorAll('.register_button button');
      console.log('allRegBtns ', allRegBtns);
      if (allRegBtns && allRegBtns.length > 0) {
        allRegBtns.forEach((el) => {
          el.addEventListener('click', function () {
            setOpenPopUp(true);
          });
        });
      }
    }, 2500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <>
      {!analyticsFlag ? (
        <Helmet>
          {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
          <title>TIIAM</title>

          {/* <!-- Google Tag Manager --> */}
          <script async src='https://www.googletagmanager.com/gtag/js?id=G-VT88KSJ6MB'></script>
          <script>
            {`  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-VT88KSJ6MB');`}
          </script>
          {/* <!-- End Google Tag Manager --> */}
          {/* <script>
            {`document.addEventListener('click', function(e) {
              if (e.target.id === 'register_button_0') {
                gtag('event', 'click', {
                  'event_category': 'Button Click',
                  'event_label': e.target.id
                });
              }
            });`}
          </script> */}
        </Helmet>
      ) : (
        <title>TIIAM</title>
      )}
      <h1 className='visibility-hidden'>
        {pageData && pageData.seo && pageData.seo.title ? pageData.seo.title : pageData && pageData.name ? pageData.name : ''}
      </h1>
      {isLoading ? (
        <Box sx={{ minHeight: '65vh', width: '100%' }} />
      ) : (
        <>
          <PageLoader layout={pageData && pageData.layout} isLoading={isLoading} customComponents={customComponents} />
          <RegisterForEvent open={openPopUp} handleClose={handleClose} />
          <UploadPhoto open={openUploadPopUp} handleClose={handleClose} />
        </>
      )}
    </>
  );
};

const styles = {
  background: {
    default: '#fafafa',
    paper: '#ffffff',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.54)',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
};

const fontSettings = {
  typography: {
    fontFamily: 'HelveticaForTarget, Helvetica, Arial, sans-serif',
    fontWeightBold: 600,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,

    h3: {
      fontWeight: '500',
    },
    h4: {
      fontWeight: '300',
    },
  },
  components: {
    MuiCssBaseline: {},
  },
};

let merged = Object.assign({}, fontSettings, styles);

const theme = createTheme({
  palette: {
    primary: indigo,
    secondary: pink,
  },
  ...merged,
});

const LoadingAuth = () => {
  return (
    <Stack alignItems='center'>
      <CircularProgress sx={{ color: 'rgb(204, 0, 0)' }} />
    </Stack>
  );
};

const Main = (props) => {
  const auth = useAuth();
  const { isAuthorized, login, logout, session } = auth;
  // const { lanId } = session ? session?.userInfo : "";
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthorized()) {
      login();
    } else {
      const { userInfo, identityToken } = session;
      dispatch(setUserSession({ userInfo, identityToken }));
    }
  }, [login, isAuthorized, dispatch, session]);

  return (
    <>
      {isAuthorized() ? (
        <BrowserRouter>
          <ScrollToTop />
          <Box
            sx={(theme) => ({
              '& .photoscapes-copy': {
                [theme.breakpoints.between(1240, 1340)]: {
                  marginTop: 0,
                },
              },
              '& .gtky-button .MuiGrid-container .MuiGrid-item button:first-of-type, .photoscapes-copy .MuiGrid-container .MuiGrid-item button:first-of-type': {
                // display: lanId && lanId.split('')[0].toLowerCase() === 'a' ? 'none' : 'flex',
                // opacity: lanId && lanId.split('')[0].toLowerCase() === 'a' ? 0.2 : 1,
                // cursor: lanId && lanId.split('')[0].toLowerCase() === 'a' ? 'none' : 'pointer',
              },
              // "& .tab-instore .MuiBox-root:nth-child(2) .MuiBox-root > div .MuiGrid-container .MuiGrid-root:nth-child(2) .MuiBox-root .body1-general > p > strong":
              //   {
              //     fontSize: "38px",
              //   },
            })}
          >
            <Layout logout={logout} session={session} />
          </Box>
        </BrowserRouter>
      ) : (
        <LoadingAuth />
      )}
    </>
  );
};

const Layout = () => {
  return (
    <>
      <header>
        <Header />
      </header>
      <Routes>
        <Route
          path='*'
          element={
            <ProtectedElement>
              <Container />
            </ProtectedElement>
          }
        />
        <Route
          path='/galleryCustom'
          element={
            <ProtectedElement>
              <Gallery />
            </ProtectedElement>
          }
        />{' '}
        <Route
          path='/register'
          element={
            <ProtectedElement>
              <AddGTKY />
            </ProtectedElement>
          }
        />
        <Route
          path='/report'
          element={
            <ProtectedElement>
              <ReportGenerator />
            </ProtectedElement>
          }
        />
        {/* <Route
          path='/scanforgift'
          element={
            <ProtectedElement>
              <Scanner />
            </ProtectedElement>
          }
        /> */}
        <Route
          path='/scanforentry'
          element={
            <ProtectedElement>
              <OuterspacesAdmission />
            </ProtectedElement>
          }
        />
        <Route
          path='/reviewforuploading'
          element={
            <ProtectedElement>
              <Review />
            </ProtectedElement>
          }
        />
        <Route
          path='/photosdashboard'
          element={
            <ProtectedElement>
              <PhotoTabs />
            </ProtectedElement>
          }
        />
        <Route
          path='/ar'
          element={
            <ProtectedElement>
              <ArExperience />
            </ProtectedElement>
          }
        />
        <Route
          path='/crossword'
          element={
            <ProtectedElement>
              <CrossWord />
            </ProtectedElement>
          }
        ></Route>
      </Routes>
      <footer>
        <Footer />
      </footer>
      <ScrollToTopButtonOnPage />
    </>
  );
};

const { clientID, authURL } = apiConfig;
function App() {
  const variantStyles = {
    // h3 & h4 font sizes
    '& h2.h3-fontxl': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h3', 'XL', 'Desktop').sizeVal,
        lineHeight: utilFunction('h3', 'XL', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h3', 'XL', 'Tablet').sizeVal,
        lineHeight: utilFunction('h3', 'XL', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h3', 'XL', 'Mobile').sizeVal,
        lineHeight: utilFunction('h3', 'XL', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h2.h3-fontxl.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h3', 'XL', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'XL', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h3', 'XL', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'XL', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h3', 'XL', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'XL', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& h2.h3-fontl': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h3', 'L', 'Desktop').sizeVal,
        lineHeight: utilFunction('h3', 'L', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h3', 'L', 'Tablet').sizeVal,
        lineHeight: utilFunction('h3', 'L', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h3', 'L', 'Mobile').sizeVal,
        lineHeight: utilFunction('h3', 'L', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h2.h3-fontl.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h3', 'L', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'L', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h3', 'L', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'L', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h3', 'L', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'L', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& h2.h3-fontm': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h3', 'M', 'Desktop').sizeVal,
        lineHeight: utilFunction('h3', 'M', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h3', 'M', 'Tablet').sizeVal,
        lineHeight: utilFunction('h3', 'M', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h3', 'M', 'Mobile').sizeVal,
        lineHeight: utilFunction('h3', 'M', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h2.h3-fontm.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h3', 'M', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'M', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h3', 'M', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'M', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h3', 'M', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'M', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& h2.h3-fonts': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h3', 'S', 'Desktop').sizeVal,
        lineHeight: utilFunction('h3', 'S', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h3', 'S', 'Tablet').sizeVal,
        lineHeight: utilFunction('h3', 'S', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h3', 'S', 'Mobile').sizeVal,
        lineHeight: utilFunction('h3', 'S', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h2.h3-fonts.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h3', 'S', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'S', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h3', 'S', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'S', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h3', 'S', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'S', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& h2.h3-fontxs': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h3', 'XS', 'Desktop').sizeVal,
        lineHeight: utilFunction('h3', 'XS', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h3', 'XS', 'Tablet').sizeVal,
        lineHeight: utilFunction('h3', 'XS', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h3', 'XS', 'Mobile').sizeVal,
        lineHeight: utilFunction('h3', 'XS', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h2.h3-fontxs.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h3', 'XS', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'XS', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h3', 'XS', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'XS', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h3', 'XS', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'XS', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& h3.h4-fontxl': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h4', 'XL', 'Desktop').sizeVal,
        lineHeight: utilFunction('h4', 'XL', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h4', 'XL', 'Tablet').sizeVal,
        lineHeight: utilFunction('h4', 'XL', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h4', 'XL', 'Mobile').sizeVal,
        lineHeight: utilFunction('h4', 'XL', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h3.h4-fontxl.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h4', 'XL', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'XL', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h4', 'XL', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'XL', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h4', 'XL', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'XL', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& h3.h4-fontl': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h4', 'L', 'Desktop').sizeVal,
        lineHeight: utilFunction('h4', 'L', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h4', 'L', 'Tablet').sizeVal,
        lineHeight: utilFunction('h4', 'L', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h4', 'L', 'Mobile').sizeVal,
        lineHeight: utilFunction('h4', 'L', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h3.h4-fontl.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h4', 'L', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'L', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h4', 'L', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'L', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h4', 'L', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'L', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& h3.h4-fontm, p.h4-fontm': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h4', 'M', 'Desktop').sizeVal,
        lineHeight: utilFunction('h4', 'M', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h4', 'M', 'Tablet').sizeVal,
        lineHeight: utilFunction('h4', 'M', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h4', 'M', 'Mobile').sizeVal,
        lineHeight: utilFunction('h4', 'M', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h3.h4-fontm.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h4', 'M', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'M', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h4', 'M', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'M', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h4', 'M', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'M', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& h3.h4-fonts': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h4', 'S', 'Desktop').sizeVal,
        lineHeight: utilFunction('h4', 'S', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h4', 'S', 'Tablet').sizeVal,
        lineHeight: utilFunction('h4', 'S', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h4', 'S', 'Mobile').sizeVal,
        lineHeight: utilFunction('h4', 'S', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h3.h4-fonts.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h4', 'S', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'S', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h4', 'S', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'S', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h4', 'S', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'S', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& h3.h4-fontxs': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h4', 'XS', 'Desktop').sizeVal,
        lineHeight: utilFunction('h4', 'XS', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h4', 'XS', 'Tablet').sizeVal,
        lineHeight: utilFunction('h4', 'XS', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h4', 'XS', 'Mobile').sizeVal,
        lineHeight: utilFunction('h4', 'XS', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h3.h4-fontxs.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h4', 'XS', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'XS', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h4', 'XS', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'XS', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h4', 'XS', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'XS', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    // button text
    '& button.button-textxl': {
      [theme.breakpoints.up('sm')]: {
        fontSize: utilFunction('button', 'XL', 'Desktop').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'XL', 'Tablet').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'XL', 'Mobile').sizeVal,
      },
    },
    '& button.button-textl': {
      [theme.breakpoints.up('sm')]: {
        fontSize: utilFunction('button', 'L', 'Desktop').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'L', 'Tablet').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'L', 'Mobile').sizeVal,
      },
    },
    '& button.button-textm': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('button', 'M', 'Desktop').sizeVal,
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('button', 'M', 'Tablet').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'M', 'Mobile').sizeVal,
      },
      // [theme.breakpoints.up("lg")]: {
      //   fontSize: `${utilFunction("button", "M", "Desktop", true).sizeVal}vw`,
      //   lineHeight:
      //     utilFunction("button", "M", "Desktop", true).sizeVal +
      //     0.11142858 +
      //     "vw",
      // },
      // [theme.breakpoints.down("lg")]: {
      //   fontSize: `${utilFunction("button", "M", "Tablet", true).sizeVal}vw`,
      //   lineHeight:
      //     utilFunction("button", "M", "Tablet", true).sizeVal +
      //     0.260714285 +
      //     "vw",
      // },
      // [theme.breakpoints.down("sm")]: {
      //   fontSize: `${utilFunction("button", "M", "Mobile", true).sizeVal}vw`,
      //   lineHeight:
      //     utilFunction("button", "M", "Mobile", true).sizeVal +
      //     0.472222221 +
      //     "vw",
      // },
    },
    '& button.button-texts': {
      [theme.breakpoints.up('sm')]: {
        fontSize: utilFunction('button', 'S', 'Desktop').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'S', 'Tablet').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'S', 'Mobile').sizeVal,
      },
    },
    '& button.button-textxs': {
      [theme.breakpoints.up('sm')]: {
        fontSize: utilFunction('button', 'XS', 'Desktop').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'XS', 'Tablet').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'XS', 'Mobile').sizeVal,
      },
    },

    //button specific tab component
    '& .MuiTabs-flexContainer button.Mui-selected': {
      fontWeight: 400,
      [theme.breakpoints.up('md')]: {
        fontSize: '24px !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px !important',
      },
    },
    '& .MuiTabs-flexContainer button': {
      fontWeight: 400,
      [theme.breakpoints.up('md')]: {
        fontSize: '24px !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px !important',
      },
    },
    '& .MuiTabs-flexContainer a': {
      [theme.breakpoints.up('md')]: {
        fontSize: '24px !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px !important',
      },
    },
    '& .MuiTabs-root': {
      [theme.breakpoints.up('md')]: {
        minHeight: '60px !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '58px !important',
      },
    },

    // body1 fontsizes
    '& .body1-fontxl p,.body1-fontxl li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'XL', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'XL', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'XL', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'XL', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'XL', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'XL', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& .body1-fontxl.responsive p,.body1-fontxl.responsive li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('body1', 'XL', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'XL', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('body1', 'XL', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'XL', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('body1', 'XL', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'XL', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& .body1-fontl p, .body1-fontl li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'L', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'L', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'L', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'L', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'L', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'L', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& .body1-fontl.responsive p,.body1-fontl.responsive li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('body1', 'L', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'L', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('body1', 'L', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'L', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('body1', 'L', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'L', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& .body1-fontm p,.body1-fontm li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'M', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'M', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'M', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& .body1-fontm.responsive p,.body1-fontm.responsive li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('body1', 'M', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'M', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('body1', 'M', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'M', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('body1', 'M', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'M', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& .body1-fonts p,.body1-fonts li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'S', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'S', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'S', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'S', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'S', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'S', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& .body1-fonts.responsive p,.body1-fonts.responsive li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('body1', 'S', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'S', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('body1', 'S', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'S', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('body1', 'S', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'S', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& .body1-fontxs p,.body1-fontxs li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'XS', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'XS', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'XS', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'XS', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'XS', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'XS', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& .body1-fontxs.responsive p,.body1-fontxs.responsive li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('body1', 'XS', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'XS', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('body1', 'XS', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'XS', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('body1', 'XS', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'XS', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },

    // anchor tags
    '& a': {
      color: 'black ',
      fontWeight: 'normal',
      fontSize: '20px',
      textDecorationColor: 'black !important',
    },
    '& .transport a': {
      color: '#FFF !important',
      textDecorationColor: '#FFF !important',
    },
    '& .customAgenda a': {
      color: '#CC0000 !important',
      textDecorationColor: '#CC0000 !important',
    },
    '& a:focus-visible': {
      outline: '1px dotted',
      color: 'black',
    },

    '& a.header-hover:hover': {
      color: '#404040 !important',
    },

    '& button:focus-visible': {
      outline: '1px dotted',
    },

    '& .body1-general a': {
      fontSize: 'inherit',
    },

    // "& .linkButtonWhite a": {
    //   color: "white !important",
    //   fontWeight: "bold",
    //   fontSize: "20px",
    //   textDecorationColor: "white !important",
    // },

    //svg icons
    '& .button-textm span svg': {
      width: '2.5rem',
      height: '2.5rem',
    },

    // numbered lists

    '& .body1-general ol': {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
      marginLeft: '35px',
    },

    '& .body1-general ol li': {
      counterIncrement: 'step-counter',
      marginBottom: '30px',
      position: 'relative',
    },

    '& .body1-general ol li::before': {
      content: 'counter(step-counter)',
      marginRight: '10px',
      backgroundColor: '#000',
      color: 'white',
      padding: '4px 10px',
      borderRadius: '50%',
      position: 'absolute',
      marginLeft: '-40px',
      marginTop: '-3px',
    },

    '& .body1-general ul': {
      paddingLeft: '20px',
    },

    // svg arrows
    '& .slider-control-centerleft': {
      [theme.breakpoints.up('md')]: {
        left: '8% !important',
        display: 'block',
      },
      button: {
        color: 'black',
      },
      display: 'none',
    },

    '& .slider-control-centerright': {
      [theme.breakpoints.up('md')]: {
        right: '8% !important',
        display: 'block',
      },
      button: {
        color: 'black',
      },
      display: 'none',
    },

    '& .slider-container .slide .MuiGrid-item': {
      // opacity: '0 !important',
    },
    '& .slider-container .slide-visible': {
      opacity: '1 !important',
    },
    // "& .slider-container .slide-visible .MuiGrid-item": {
    //   opacity: "1 !important",
    // },
    // "& .circle-carousel .slide-current .Overlay": {
    //   opacity: 0,
    // },

    // "& .circle-carousel :hover": {
    //   "&.slide-visible": { opacity: "0.3 !important" },
    // },
    // "& .circle-carousel .slide-current:hover": {
    //   opacity: "1 !important",
    // },

    // "& .circle-carousel .circle-carousel-content-container ": {
    //   width: desktop ? "60%" : tablet ? "70%" : "90%",
    // },
    // "& .circle-carousel .circle-carousel-content-container .circle-carousel-content":
    //   {
    //     opacity: "0 !important",
    //   },
    // "& .circle-carousel .slide-current .circle-carousel-content": {
    //   opacity: "1 !important",
    // },
    // "& .circle-carousel .circle-carousel-content-container .circle-carousel-content-image":
    //   {
    //     opacity: "0 !important",
    //   },
    // "& .circle-carousel .slide-current .circle-carousel-content-image ": {
    //   opacity: "1 !important",
    // },
    // "& .circle-carousel .slider-container .slider-control-bottomcenter .pagingDotsCustom":
    //   {
    //     [theme.breakpoints.up("lg")]: {
    //       svg: {
    //         width: "13px !important",
    //         height: "13px !important",
    //       },
    //     },
    //     [theme.breakpoints.down("lg")]: {
    //       svg: {
    //         width: "13px !important",
    //         height: "13px !important",
    //       },
    //     },
    //     [theme.breakpoints.down("sm")]: {
    //       svg: {
    //         width: "12px !important",
    //         height: "12px !important",
    //       },
    //     },
    //   },

    // //carousel
    '& .slider-container .slider-control-bottomcenter .pagingDots': {
      top: '-20px !important',
      // [theme.breakpoints.up('sm')]: {
      //   svg: {
      //     width: '13px',
      //     height: '13px',
      //     circle: {
      //       cx: 6,
      //       cy: 6,
      //       r: 6,
      //     },
      //   },
      // },
      // [theme.breakpoints.down('sm')]: {
      //   top: '20px !important',
      //   svg: {
      //     width: '13px',
      //     height: '13px',
      //     circle: {
      //       cx: 6,
      //       cy: 6,
      //       r: 6,
      //     },
      //   },
      // },
    },
    '& .slider-container .slider-list': {
      [theme.breakpoints.up('md')]: {
        marginBottom: '20px',
      },
      [theme.breakpoints.up('sm')]: {
        marginBottom: '25px',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '30px',
      },
    },
    '& .stats-li li': {
      listStyle: 'none',
    },
    '& .stats-li ul': {
      paddingLeft: 0,
    },
    '& .visibility-hidden': {
      position: 'absolute',
      clip: 'rect(1px, 1px, 1px, 1px)',
      clipPath: 'inset(0px 0px 99.9% 99.9%)',
      overflow: 'hidden',
      height: 1,
      width: 1,
      padding: 0,
      border: 0,
    },
    '& a.body1-fonts': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'S', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'S', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'S', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'S', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'S', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'S', 'Mobile').sizeVal + 2 + 'px',
      },
    },

    '& a.body1-fontm': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'M', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'M', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'M', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Mobile').sizeVal + 2 + 'px',
      },
    },

    '& a.body1-fontlarge': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'L', 'Desktop').sizeVal,
        lineHeight: '30px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'L', 'Tablet').sizeVal,
        lineHeight: '30px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'L', 'Mobile').sizeVal,
        lineHeight: '30px',
      },
    },
    '& a.body1-fontsmall': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'S', 'Desktop').sizeVal,
        lineHeight: '30px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'S', 'Tablet').sizeVal,
        lineHeight: '30px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'S', 'Mobile').sizeVal,
        lineHeight: '30px',
      },
    },

    '& a.body1-fontmedium': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'M', 'Desktop').sizeVal,
        lineHeight: '30px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'M', 'Tablet').sizeVal,
        lineHeight: '30px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'M', 'Mobile').sizeVal,
        lineHeight: '30px',
      },
    },

    '& a.body1-fontl': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'L', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'L', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'L', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'L', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'L', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'L', 'Mobile').sizeVal + 2 + 'px',
      },
    },

    '& a.body1-fonts.header': {
      [theme.breakpoints.between(900, 1024)]: {
        fontSize: '1.3vw',
        lineHeight: utilFunction('body1', 'S', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.between(1024, 1200)]: {
        fontSize: '1.65vw',
        lineHeight: utilFunction('body1', 'S', 'Tablet').sizeVal + 2 + 'px',
      },
    },

    '& a.body1-fonts.footer': {
      [theme.breakpoints.between(900, 1024)]: {
        fontSize: '1vw',
        lineHeight: utilFunction('body1', 'S', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.between(1024, 1200)]: {
        fontSize: '1.5vw',
        lineHeight: utilFunction('body1', 'S', 'Tablet').sizeVal + 2 + 'px',
      },
    },
    '& .tab-instore .body1-general p': {
      marginTop: 12,
    },
  };
  return (
    <div className='App'>
      <StencilTenant tenantId='63fc5013bc1ce171101c783e'>
        <ThemeProvider theme={theme}>
          <AuthProvider authorizationUrl={authURL} clientId={clientID} loadingIndicator={() => <LoadingAuth />}>
            <Provider store={store}>
              <GlobalStyles styles={variantStyles} />
              <Main />
            </Provider>
          </AuthProvider>
        </ThemeProvider>
      </StencilTenant>
    </div>
  );
}

export default App;
