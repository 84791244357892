import { Grid, Typography, useMediaQuery } from '@mui/material';
import Carousel from 'nuka-carousel';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useState } from 'react';
import VideoPlayer from '../Utils/VideoPlayer';
export default function VideoCarousel(props) {
  const desktop = useMediaQuery((theme) => theme.breakpoints.up(1701));
  const tab = useMediaQuery((theme) => theme.breakpoints.between(768, 1023));
  const mobile = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const windowsLaptop = useMediaQuery((theme) => theme.breakpoints.between(1024, 1700));
  const [url, setURL] = useState('');

  const [poster, setPoster] = useState('');
  const handleClick = (slideMedia) => {
    setURL(slideMedia?.secondaryMedia);
    setPoster(slideMedia?.mediaSrc);
    setOpen(true);
  };
  const userStyles = {
    carouselProps: {
      // animation: desktop ? '' : 'zoom',
      //   cellSpacing: desktop ? 3 : 0,
      wrapAround: true,
      cellAlign: 'center',
      slidesToShow: desktop || windowsLaptop ? 3 : 1.2,
      edgeEasing: 'easeLinear',
      enableKeyboardControls: true,
      autoplay: false,
      width: '100%',
    },
    sliderContainer: {
      // border: '1px solid red',
      marginBottom: '20px',
      marginTop: tab || mobile ? '0px' : windowsLaptop ? '100px ' : '0px',
      '& .slide': {
        margin: '0 10px',
        width: '100% !important',
      },
      '& .slider-container': {
        // border: '5px solid blue',
        width: '100%',
        padding: '0 30px !important',
      },
      '.slider-container .slide .MuiGrid-item': {
        opacity: 'inherit !important',
      },

      '& .slider-control-bottomcenter': {
        position: 'absolute',
        bottom: desktop ? '-10% !important' : '-8% !important',
        '& .active ': {
          //   transform: 'scale(2)',
        },
      },
      '& .slider-control-centerleft, & .slider-control-centerright': {
        // marginTop: '-40px !important',
      },
    },
    speakerName: {
      fontFamily: 'TargetBold',
    },
  };
  const [open, setOpen] = useState(false);
  console.log('Props ', props.slides.slide);
  props.slides.slide.forEach(({ heading }) => {
    console.log('slide ', heading?.text);
  });
  return (
    <>
      <Grid container spacing={0} alignItems='center' justifyContent='center' sx={userStyles.sliderContainer}>
        <Carousel
          {...userStyles.carouselProps}
          renderAnnounceSlideMessage={({ currentSlide, slideCount }) => `
            Slide ${currentSlide + 1} of ${slideCount}
            `}
          defaultControlsConfig={{
            // prevButtonClassName: classNames(userStyles.prevBtn),
            nextButtonText: <NavigateNextIcon aria-label='next slide' fontSize={desktop ? 'large' : tab || mobile || windowsLaptop ? '10px' : 'small'} />,
            prevButtonText: <NavigateBeforeIcon aria-label='previous slide' fontSize={desktop ? 'large' : '10px'} />,

            pagingDotsStyle: {
              fill: '#CC0000',
              padding: mobile ? '6px' : '20px',
              // marginTop: desktop ? '-30px' : '-60px',
              // border: '1px solid',
            },
          }}
        >
          {props.slides.slide.map(({ slideMedia, body }, index) => (
            <Grid
              item
              md={12}
              padding={!mobile ? '1rem 0' : '1.5rem'}
              key={slideMedia?.mediaSrc}
              onClick={() => handleClick(slideMedia)}
              style={{ cursor: 'pointer' }}
            >
              <img
                src={slideMedia?.mediaSrc}
                aria-label="{'play video of ' + video.name}"
                width={'100%'}
                style={{ cursor: 'pointer' }}
                // alt={'play video of ' + video.name}
                //   title={'play video of ' + video.name}
              />

              <div>
                {/* <Typography variant='h6' component='div' sx={{ marginBottom: '15px', fontWeight: '400', color: '#CC0000' }}>
                  {heading?.text}
                </Typography> */}
                <Typography variant='body2' sx={{ fontSize: '14px', fontWeight: '500' }} dangerouslySetInnerHTML={{ __html: body?.text }}></Typography>
              </div>
            </Grid>
          ))}
        </Carousel>
        <VideoPlayer open={open} handleClose={() => setOpen(false)} videoSrc={url} poster={poster} />
      </Grid>
    </>
  );
}
