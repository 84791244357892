export const courtyardPhotos = [
  {
    name: 'a',
    alt: 'Preparations underway for a painting session at Courtyard of Joy.',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/a.jpg',
  },
  {
    name: 'b',
    alt: 'Team member getting started with measuring her canvas for her painting. ',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/b.jpg',
  },
  {
    name: 'c',
    alt: 'Team member integrating a vibrant red Bullseye logo in their painting. ',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/c.jpg',
  },
  {
    name: 'd',
    alt: 'Team members collaborating on painting an Indian pattern-inspired Target logo.',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/d.jpg',
  },
  {
    name: 'e',
    alt: 'Team member painting Bullseye, our mascot, with a pink heart in his hand.',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/e.jpg',
  },
  {
    name: 'f',
    alt: 'Team members watching a musical performace during Courtyard of Joy. ',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/f.jpg',
  },
  {
    name: 'g',
    alt: 'Delicious snacks made by Team members for Courtyard of Joy. ',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/g.jpg',
  },
  {
    name: 'h',
    alt: 'Team member playing the saxophone for Courtyard of Joy. ',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/h.jpg',
  },
  {
    name: 'j',
    alt: 'Audience enjoying performances at the Courtyard of Joy event. ',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/j.jpg',
  },
  {
    name: 'k',
    alt: 'Team member arranging cupcakes in her stall at the Courtyard of Joy. ',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/k.jpg',
  },
  {
    name: 'l',
    alt: 'Pink-coloured drinks on a tray of roses in a stall at the Courtyard of Joy. ',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/l.jpg',
  },
  {
    name: 'm',
    alt: 'Team member playing the guitar on a podium for Courtyard of Joy.',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/m.jpg',
  },
  {
    name: 'n',
    alt: 'Two Team members painting on a sheet of paper for an activity at Courtyard of Joy. ',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/n.jpg',
  },
  {
    name: 'o',
    alt: 'Team member performing to the audience at Courtyard of Joy. ',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/o.jpg',
  },
  {
    name: 'p',
    alt: 'Two trays of cupcakes on display at a stall during the Courtyard of Joy event. ',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/p.jpg',
  },
  {
    name: '0',
    alt: '_',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/A0.png',
  },
  {
    name: '1',
    alt: '_',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/B1.png',
  },
  {
    name: '2',
    alt: '_',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/C2.png',
  },
  {
    name: '3',
    alt: '_',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/D3.jpg',
  },
  {
    name: '4',
    alt: '_',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/E4.png',
  },
  {
    name: '5',
    alt: '_',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/F5.jpg',
  },
  {
    name: '6',
    alt: '_',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/H6.png',
  },
  {
    name: '7',
    alt: '_',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/I7.jpg',
  },
  {
    name: '8',
    alt: '_',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/J8.jpg',
  },
  {
    name: '9',
    alt: '_',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/K9.jpg',
  },
  {
    name: '10',
    alt: '_',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/L10.jpg',
  },
  {
    name: '11',
    alt: '_',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/M11.jpg',
  },
  {
    name: '12',
    alt: '_',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/N12.jpg',
  },
  {
    name: '13',
    alt: '_',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/O13.jpg',
  },
  {
    name: '14',
    alt: '_',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/P14.jpg',
  },
  {
    name: '15',
    alt: '_',
    src: 'https://gfc.target.com/tiiam/tiiam23/courtyard/Q15.jpg',
  },
];
export const galleryPhotos = [
  {
    name: '0',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/028A4402.jpg',
  },
  {
    name: '1',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/028A4526.jpg',
  },
  {
    name: '2',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/028A4776.jpg',
  },
  {
    name: '3',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/Alina-Alam.jpg',
  },
  {
    name: '4',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/Deepti Bopiah.jpg',
  },
  {
    name: '5',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/Dhanya Ravi.jpg',
  },
  {
    name: '6',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/Elisabeth Yorke.jpg',
  },
  {
    name: '7',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/Giftable.jpg',
  },
  {
    name: '8',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/Human Library.jpg',
  },
  {
    name: '9',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/Human Library_02.jpg',
  },
  {
    name: '10',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/Human Library_03.jpg',
  },
  {
    name: '11',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/LT@lobby.jpg',
  },
  {
    name: '12',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/Leadership with Directors 02.jpg',
  },
  {
    name: '13',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/Mounica Tata.jpg',
  },
  {
    name: '14',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/Mounica Tata_02.jpg',
  },
  {
    name: '15',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/Rajiv Mathew.jpg',
  },
  {
    name: '16',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/Roberto Narain.jpg',
  },
  {
    name: '17',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/Sahar Mansoor.jpg',
  },
  {
    name: '18',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/Sahar Mansoor_02.jpg',
  },
  {
    name: '19',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/Shwetambari Shetty.jpg',
  },
  {
    name: '20',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/Supriya.jpg',
  },
  {
    name: '21',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/Team Floor walk.jpg',
  },
  {
    name: '22',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/Vishwanath Mallabadi.jpg',
  },
  {
    name: '23',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/YF1A4632.jpg',
  },
  {
    name: '24',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/YF1A4644.jpg',
  },
  {
    name: '25',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/YF1A4686.jpg',
  },
  {
    name: '26',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/tiiam2024_12.jpg',
  },
  {
    name: '27',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/tiiam2024_13.jpg',
  },
  {
    name: '28',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/tiiam2024_17.jpg',
  },
  {
    name: '29',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/tiiam2024_19.jpg',
  },
  {
    name: '30',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/tiiam2024_21.jpg',
  },
  {
    name: '31',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/tiiam2024_7.jpg',
  },
  {
    name: '32',
    alt: 'Candid photos from the event TIIAM 2024',
    src: 'https://gfc.target.com/tiiam/tiiam24/galleryimages/tiiam2024_8.jpg',
  },
];
export const galleryVideos = [
  {
    url: 'https://gfc.target.com/tiiam/tiiam24/galleryvideos/Cara1.png',
    videoUrl: 'https://gfc.target.com/tiiam/tiiam24/galleryvideos/tiiam2024-CaraSylvester-forhub-v1.mp4',
    title: '',
    name: 'Cara Sylvester | EVP & Chief Guest Experience Officer',
    bio: '',
    captions: '',
  },
  {
    url: 'https://gfc.target.com/tiiam/tiiam24/galleryvideos/Brian.png',
    videoUrl: 'https://gfc.target.com/tiiam/tiiam24/galleryvideos/tiiam2024-BrianCornell-forhub-v1.mp4',
    title: '',
    name: 'Brian Cornell | CEO',
    bio: '',
    captions: '',
  },
  {
    url: 'https://gfc.target.com/tiiam/tiiam24/galleryvideos/Andrea.png',
    videoUrl: 'https://gfc.target.com/tiiam/tiiam24/galleryvideos/tiiam2024-AndreaZimmerman-forhub-v1.mp4',
    title: '',
    name: 'Andrea Zimmerman | SVP & President, Target in India',
    bio: '',
    captions: '',
  },
  {
    url: 'https://gfc.target.com/tiiam/tiiam24/galleryvideos/Rick.png',
    videoUrl: 'https://gfc.target.com/tiiam/tiiam24/galleryvideos/tiiam2024-RickGomez-forhub-v1.mp4',
    title: '',
    name: 'Rick Gomez | EVP & Chief Food Essentials & Beauty Officer',
    bio: '',
    captions: '',
  },
  {
    url: 'https://gfc.target.com/tiiam/tiiam24/galleryvideos/leader.png',
    videoUrl: 'https://gfc.target.com/tiiam/tiiam24/galleryvideos/LeadershipDanceFullScreen.mp4',
    title: '',
    name: 'Leadership Performance | TIIAM 2024 ',
    bio: '',
    captions: '',
  },
  {
    url: 'https://gfc.target.com/tiiam/tiiam24/galleryvideos/engage.png',
    videoUrl: 'https://gfc.target.com/tiiam/tiiam24/galleryvideos/EngagetainmentFullRes.mp4',
    title: '',
    name: 'Team Member Performance | TIIAM 2024 ',
    bio: '',
    captions: '',
  },
];
