export const targetTriviaDayOne = {
  across: {
    1: {
      clue: "A printed picture, also the name of North and Central Coast wines",
      answer: "PHOTOGRAPH",
      row: 0,
      col: 0,
    },
    4: {
      clue: "Colour me bright - a world of art supplies",
      answer: "MONDOLLAMA",
      row: 2,
      col: 1,
    },
    5: {
      clue: "Fun clothes for kids, incl. a line of adaptive clothing",
      answer: "CAT&JACK",
      row: 4,
      col: 2,
    },
    8: {
      clue: "Pour out some fun with these pre-mixed cocktails",
      answer: "CASACANTINA",
      row: 6,
      col: 4,
    },
    9: {
      clue: "The 'brightest' organizers for all your rooms",
      answer: "BRIGHTROOM",
      row: 8,
      col: 1,
    },
    10: {
      clue: "Eye-catching, on-trend tech accessories",
      answer: "HEYDAY",
      row: 11,
      col: 7,
    },
    11: {
      clue: "Amazing activewear, no matter your style, speed or ability",
      answer: "ALLINMOTION",
      row: 13,
      col: 1,
    },
  },
  down: {
    2: {
      clue: "Staples and more for your pantry (and parties)",
      answer: "GOOD&GATHER",
      row: 0,
      col: 5,
    },
    3: {
      clue: "Om-nom-nom...an all-time favorite",
      answer: "FAVORITEDAY",
      row: 1,
      col: 12,
    },
    6: {
      clue: "Fun, approachable wines for every occasion and every mood",
      answer: "HEADLINER",
      row: 4,
      col: 14,
    },
    7: {
      clue: "Relaxed, classic home décor aesthetic to help create a warm, welcoming environment",
      answer: "THRESHOLD",
      row: 6,
      col: 2,
    },
  },
};

export const targetTriviaDayTwo = {
  across: {
    1: {
      clue: "The Target mascot belongs to this breed",
      answer: "BULLTERRIER",
      row: 0,
      col: 0,
    },
    3: {
      clue: "Target's brand for apparel and accessories line designed for kids ages 4-12",
      answer: "ARTCLASS",
      row: 2,
      col: 3,
    },
    6: {
      clue: "The city where Target opened the most number of stores in a day",
      answer: "CHICAGO",
      row: 4,
      col: 6,
    },
    9: {
      clue: "A type of lab that Target runs",
      answer: "FORENSICS",
      row: 10,
      col: 0,
    },
    10: {
      clue: "A curated collection of products designed to reduce waste, shop sustainably",
      answer: "TARGETZERO",
      row: 12,
      col: 1,
    },
  },
  down: {
    2: {
      clue: "A visual icon representing products formulated without a group of commonly unwanted chemicals",
      answer: "TARGETCLEAN",
      row: 0,
      col: 4,
    },
    4: {
      clue: "A coffee shop that's inside most Target stores",
      answer: "STARBUCKS",
      row: 2,
      col: 10,
    },
    5: {
      clue: "An approachable modern home assortment designed for everyday life",
      answer: "PROJECT62",
      row: 2,
      col: 12,
    },
    7: {
      clue: "The last state in the US to get a Target store",
      answer: "VERMONT",
      row: 6,
      col: 1,
    },
    8: {
      clue: "The company Target acquired to enable same-day delivery service",
      answer: "SHIPT",
      row: 8,
      col: 6,
    },
  },
};

export const targetTriviaDayThree = {
  across: {
    4: {
      clue: 'The TII newsletter in your Inbox every Wednesday',
      answer: 'NAMASTHEY',
      row: 7,
      col: 4,
    },
    5: {
      clue: "Target's own intranet",
      answer: "INSIDETGT",
      row: 10,
      col: 2,
    },
    6: {
      clue: "The daily newsletter for all Target TMs",
      answer: "TGTBRIEFLY",
      row: 12,
      col: 0,
    },
    7: {
      clue: "Target's credit/debit/reloadable discount card",
      answer: "REDCARD",
      row: 14,
      col: 7,
    },
    8: {
      clue: "Target's committee to accelerate our DE&I strategy for our Black TMs & guests",
      answer: "REACH",
      row: 16,
      col: 5,
    },
  },
  down: {
    1: {
      clue: "The pharmacy provider at Target stores",
      answer: "CVSHEALTH",
      row: 0,
      col: 9,
    },
    2: {
      clue: "The first-ever Target owned brand",
      answer: "HONORS",
      row: 5,
      col: 4,
    },
    3: {
      clue: "What Target's sustainability strategy is called",
      answer: "TARGETFORWARD",
      row: 6,
      col: 7,
    },
  },
};
