import { Grid, Typography, useMediaQuery } from "@mui/material";
import _ from "lodash";
import React, { useEffect } from "react";
import galleryData from "./GalleryData.json";
import memberStories from "./Stories.json";
import Stories from "./Stories";
import Testimonials from "./Testimonials";
import { useLocation } from "react-router-dom";

const RenderVideoCards = ({ galleryObj, galleryStyles }) => {
  return (
    <Grid container sx={{ width: "80%", margin: "0 auto 50px auto" }}>
      <Grid item md={12} sx={{ m: "0 20px" }}>
        <Typography sx={galleryStyles.eventTitle}>
          {galleryObj.event_title}
        </Typography>
        {galleryObj.event_title === "Target Socials" ? (
          <Typography sx={galleryStyles.eventDesc}>
            {galleryObj.event_description}
          </Typography>
        ) : null}
      </Grid>
      {galleryObj.sessions.map((sessionObj, session_index) => (
        <React.Fragment key={session_index}>
          <Grid item md={4} sm={12} sx={{ p: "20px" }}>
            <a
              href={sessionObj.session_streaming_url}
              target="_blank"
              rel="noreferrer"
            >
              <img
                width="100%"
                src={sessionObj.session_tumbnail}
                alt={sessionObj.session_alt_text}
              />
            </a>
            {galleryObj.event_title === "Target Socials" ? (
              <Typography>{`Episode ${session_index + 1}`}</Typography>
            ) : null}
            {sessionObj.speakers &&
              sessionObj.speakers.map((speakerObj, speaker_index) => (
                <div key={speaker_index}>
                  <Typography>
                    <span style={galleryStyles.speakerName}>
                      {speakerObj.speaker_name}
                    </span>{" "}
                    <span>| {speakerObj.speaker_designation}</span>
                  </Typography>
                </div>
              ))}
            <Typography>{sessionObj.session_description}</Typography>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

const Videos = () => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const location = useLocation();
  const galleryStyles = {
    eventTitle: {
      fontFamily: "TargetBold",
      fontSize: mobile ? "30px" : "35px",
      texrAlign: "left !important",
    },
    eventDesc: {
      fontSize: "18px",
    },
    speakerName: {
      fontFamily: "TargetBold",
    },
  };

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  const [
    outerSpaces,
    talksWithTii,
    leadershipPresentations,
    targetSocials,
    otherVideos,
  ] =
    location.pathname.slice(1) === "gallery" ||
    location.pathname.slice(1) === "photoscapes"
      ? galleryData
      : "";

  const memberStoriesChunks = _.chunk(memberStories, 13);

  return (
    <>
      <Grid container style={{ marginTop: "60px" }}>
        <RenderVideoCards
          galleryObj={outerSpaces}
          galleryStyles={galleryStyles}
        />
        <RenderVideoCards
          galleryObj={talksWithTii}
          galleryStyles={galleryStyles}
        />
        <RenderVideoCards
          galleryObj={leadershipPresentations}
          galleryStyles={galleryStyles}
        />

        <Grid container sx={{ width: "80%", margin: "0 auto 50px auto" }}>
          <Grid item md={12} sx={{ m: "0 20px" }}>
            <Typography sx={galleryStyles.eventTitle}>
              {"Team Member Stories"}
            </Typography>
          </Grid>
          <Stories memberStories={memberStoriesChunks[0]} />
          <Stories memberStories={memberStoriesChunks[1]} />
        </Grid>

        <Grid container sx={{ width: "80%", margin: "0 auto 50px auto" }}>
          <Grid item md={12} sx={{ m: "0 20px" }}>
            <Typography sx={galleryStyles.eventTitle}>
              {"Team Member Voices"}
            </Typography>
          </Grid>
          <Testimonials />
        </Grid>

        <RenderVideoCards
          galleryObj={targetSocials}
          galleryStyles={galleryStyles}
        />
        <RenderVideoCards
          galleryObj={otherVideos}
          galleryStyles={galleryStyles}
        />
      </Grid>
    </>
  );
};

export default Videos;
